import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CONSTANTS, events } from '@gelato/analytics-datalayer';
import { serviceRequestFactory } from '@nmx/utils/dist/services/ServiceRequestService/Frontend';
import { leadServiceFactory } from '@nmx/utils/dist/services/LeadService/Frontend';
import Location from '@nmx/utils/dist/utilities/frontend/location';
import {
  goals,
  incomes,
  buildServiceRequestPayload,
  buildLeadPayload,
  validatePolicyOwnerSelected,
  validateFirstName,
  validateLastName,
  validateZipCode,
  validatePhone,
  validateEmail,
  validateAge,
  validateGoal,
  validateIncomeRange,
  validateWealthRange,
  validateComments,
} from './utilities';
import Button from '../../base/Button';
import { Row, Col } from '../../base/Grid';
import TextField from '../../form/TextField';
import Select from '../../form/Select';
import Form from '../../form/Form';
import FormRow from '../../form/FormRow';
import RadioGroup from '../../form/RadioGroup';
import ThankYou from '../../form/ThankYou';
import Error from '../../form/Error';

import RecaptchaInFormBadge from './RecaptchaInFormBadge';
import Recaptcha from './Recaptcha'; // TODO: change where recaptcha lives and make it an exportable component

import { Styles } from './styles';

export const AgentFormComponent = (props) => {
  const {
    applicationId,
    formLocation,
    onFormEngaged,
    id,
    // customName,
    recaptchaInvisible,
  } = props;

  const styles = Styles();
  // form states
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrored, setFormErrored] = useState(false);

  // form value fields
  const [values, setValues] = useState({
    application_id: '',
    policy_owner: 'No', // setting default radio option to no
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    age: '',
    zip: '',
    goal: '',
    income_range: '',
    wealth_range: '',
    comments: '',
    campaign_name: props.campaign,
  });
  // form error states
  const [errors, setErrors] = useState({
    policy_owner: false,
    first_name: false,
    last_name: false,
    phone: false,
    email: false,
    age: false,
    zip: false,
    goal: false,
    income_range: false,
    wealth_range: false,
    comments: false,
  });

  const { GENERAL } = CONSTANTS.NAME_LEAD_FORM_EXPERIENCES;

  const {
    SERVICE_REQUEST,
    LEAD,
  } = CONSTANTS.NAME_LEAD_FORM_SUBMIT_TYPE;

  // when component mounts
  useEffect(() => {
    // this is to set the initial state
    setValues({
      ...values,
      application_id: applicationId,
    });
    if (Location.getQueryParam('campName')) {
      setValues({
        ...values,
        campaign_name: Location.getQueryParam('campName'),
      });
    }
    if (props.isPcg) {
      // pcg profile default to working with fr state
      setValues({
        ...values,
        policy_owner: 'Yes',
      });
    }
    events.leadForms.load({ experience: GENERAL });
  }, []);

  const logError = (message, error) => {
    console.error(message, error);
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    // Inform parent component of engagement with form
    onFormEngaged();
  };

  const handleSubmitError = (error, isLeadCall) => {
    try {
      if (window.grecaptcha) {
        window.grecaptcha.reset();
      }
      // if recaptcha execute fails, show error
      logError(
        `Error submitting ${
          isLeadCall
            ? 'lead'
            : 'service request'
        } on ${formLocation}.`,
        error,
      );
      setFormErrored(true);
    } catch (recaptchaError) {
      logError(`Error resetting recaptcha on ${formLocation}.`, recaptchaError);
    }
  };

  const handleReCaptchaCallback = (recaptchaReponseToken) => {
    let request = null;
    let payload = null;
    let isLeadCall = false;
    if (values.policy_owner === 'Yes') {
      payload = buildServiceRequestPayload(
        values,
        props.agentnumber,
        recaptchaReponseToken,
        props.source,
        props.topic,
      );

      const ServiceRequestService = serviceRequestFactory({ baseURL: props.config.services.serviceRequestApiBaseUrl });

      request = ServiceRequestService.submitServiceRequest(payload);
    } else {
      payload = buildLeadPayload(
        values,
        props.agentnumber,
        recaptchaReponseToken,
        props.source,
        props.topic,
      );

      const LeadService = leadServiceFactory({ baseURL: props.config.services.leadApiBaseUrl });
      request = LeadService.submitLead(payload);
      isLeadCall = true;
    }

    try {
      request
        .then(() => {
          setFormSubmitted(true);
          // Success! send adobe data if this is a lead
          events.leadForms.complete({
            experience: GENERAL,
            submissionType: isLeadCall ? LEAD : SERVICE_REQUEST,
          });
        })
        .catch((error) => {
          handleSubmitError(error, isLeadCall);
        });
    } catch (error) {
      handleSubmitError(error, isLeadCall);
      events.leadForms.completeFailure({
        experience: GENERAL,
        errorMsg: `Unable to submit agent form with error ${error}`,
      });
    }
  };

  // call this function when we've validated the form and are ready to submit
  const executeRecaptchaAndSubmit = () => {
    try {
      if (props.useRecaptcha) {
        window.grecaptcha.execute();
      } else {
        handleReCaptchaCallback(formLocation);
      }
    } catch (error) {
      // if recaptcha execute fails, show error
      logError(`Error executing recaptcha on ${formLocation}.`, error);
      setFormErrored(true);
    }
  };

  // call this function when we've validated the form and there's an error
  const resetRecaptcha = () => {
    try {
      if (window.grecaptcha) {
        window.grecaptcha.reset();
      }
      setIsSubmitting(false);
    } catch (error) {
      // if recaptcha execute fails, show error
      logError(`Error resetting recaptcha on ${formLocation}.`, error);
      setFormErrored(true);
    }
  };

  const handleSubmit = (event) => {
    setIsSubmitting(true);
    event.preventDefault();
    event.stopPropagation();

    const errorCheck = {
      policy_owner: !validatePolicyOwnerSelected(values.policy_owner),
      first_name: !validateFirstName(values.first_name),
      last_name: !validateLastName(values.last_name),
      phone: !validatePhone(values.phone),
      email: !validateEmail(values.email),
      age: !validateAge(values.age, values.policy_owner),
      zip: !validateZipCode(values.zip),
      goal: !validateGoal(values.goal, values.policy_owner),
      income_range: !validateIncomeRange(
        values.income_range,
        values.policy_owner,
      ),
      wealth_range: !validateWealthRange(
        values.wealth_range,
        values.income_range,
        values.policy_owner,
      ),
      comments: !validateComments(values.comments, values.policy_owner),
    };

    setErrors(errorCheck);
    const isValid
      = !errorCheck.policy_owner
      && !errorCheck.first_name
      && !errorCheck.last_name
      && !errorCheck.phone
      && !errorCheck.email
      && !errorCheck.age
      && !errorCheck.zip
      && !errorCheck.goal
      && !errorCheck.income_range
      && !errorCheck.wealth_range
      && !errorCheck.comments;

    if (isValid) {
      executeRecaptchaAndSubmit();
    } else {
      resetRecaptcha();
    }
  };

  const handleAdobeDirectCall = (event) => {
    const { name, value } = event.target;

    // return early if value is empty, Xai asked us not to send if the value is empty
    if (!value) return;

    events.leadForms.interact({
      experience: GENERAL,
      actionType: name,
      actionValues: 'complete',
    });
  };

  if (formSubmitted) {
    return (
      <ThankYou
        thankYouTextDescription={
          values.policy_owner === 'Yes'
            ? 'Your financial advisor will get back to you soon.'
            : null
        }
      />
    );
  }

  if (formErrored) {
    return <Error />;
  }

  const options = [{
    value: 'Yes',
    label: 'Yes',
  },
  {
    value: 'No',
    label: 'No',
  }];

  const initialWorkingTogetherValue = 'No';

  return (
    <div>
      <Form
        {...applicationId && { application_id: applicationId }}
        legend="Northwestern Mutual Agent Form"
        id={id}
        onSubmit={handleSubmit}>
        <FormRow>
          <Col xsmall={12}>
            <RadioGroup
              disabled={isSubmitting}
              error={errors.policy_owner}
              errorMessage='Please select if you are currently working with this advisor.'
              label='Are you currently working together?'
              name='policy_owner'
              onChange={(e) => {
                handleChange(e);
                handleAdobeDirectCall(e);
              }}
              radioValues={options}
              selectedRadioValue={initialWorkingTogetherValue}
              variant='visualPicker'
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <hr /> {/* tsk tsk TODO: remove */}
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <p>Answer some questions so I&#39;ll have a better idea of how I can help you.</p>
          </Col>
        </FormRow>
        <Row>
          <Col xsmall={12} small={6} styles={styles.col}>
            <TextField
              label="First Name"
              name="first_name"
              type="text"
              id="nm-agent-form-first-name"
              required
              maxLength={150}
              value={values.first_name}
              onChange={handleChange}
              onBlur={handleAdobeDirectCall}
              disabled={isSubmitting}
              error={errors.first_name}
              errorMessage="Please enter a valid first name."
            />
          </Col>
          <Col xsmall={12} small={6} styles={styles.col}>
            <TextField
              label="Last Name"
              name="last_name"
              type="text"
              id="nm-agent-form-last-name"
              required
              maxLength={150}
              value={values.last_name}
              onChange={handleChange}
              onBlur={handleAdobeDirectCall}
              disabled={isSubmitting}
              error={errors.last_name}
              errorMessage="Please enter a valid last name."
            />
          </Col>

          <Col xsmall={12} small={6} styles={styles.col}>
            <TextField
              label="Zip Code"
              name="zip"
              id="nm-agent-form-zip"
              required
              maxLength={5}
              type="text"
              value={values.zip}
              onChange={handleChange}
              onBlur={handleAdobeDirectCall}
              disabled={isSubmitting}
              error={errors.zip}
              errorMessage="Please enter a valid zip code."
            />
          </Col>
          <Col xsmall={12} small={6} styles={styles.col}>
            <TextField
              label="Phone Number"
              name="phone"
              type="text"
              id="nm-agent-form-phone"
              required
              maxLength={14}
              value={values.phone}
              onChange={handleChange}
              onBlur={handleAdobeDirectCall}
              disabled={isSubmitting}
              error={errors.phone}
              errorMessage="Please enter a valid phone number."
            />
          </Col>
          <Col xsmall={12} styles={styles.col}>
            <TextField
              label="Email"
              name="email"
              type="text"
              id="nm-agent-form-email"
              required
              maxLength={150}
              value={values.email}
              onChange={handleChange}
              onBlur={handleAdobeDirectCall}
              disabled={isSubmitting}
              error={errors.email}
              errorMessage="Please enter a valid email address."
            />
          </Col>

          {/* Lead Fields */
            values.policy_owner !== 'Yes' && (
              <Col xsmall={12} small={6} styles={styles.col}>
                <TextField
                  label="Age"
                  name="age"
                  maxLength={3}
                  type="text"
                  id="nm-lead-form-age"
                  required
                  value={values.age}
                  onChange={handleChange}
                  onBlur={handleAdobeDirectCall}
                  disabled={isSubmitting}
                  error={errors.age}
                  errorMessage="Please enter a valid age."
                />
              </Col>
            )
          }
          {/* Lead Fields */
            values.policy_owner !== 'Yes' && (
              <Col xsmall={12} small={6} styles={styles.col}>
                <Select
                  label="My biggest financial goal is to:"
                  name="goal"
                  id="nm-lead-form-goal"
                  required
                  value={values.goal}
                  onChange={(e) => {
                    handleChange(e);
                    handleAdobeDirectCall(e);
                  }}
                  onBlur={(e) => {
                    handleChange(e);
                  }}
                  disabled={isSubmitting}
                  error={errors.goal}
                  errorMessage="Please select a goal">
                  {goals.map((option) => (
                    <option
                      key={option.name}
                      value={option.value}
                      disabled={option.disabled}>
                      {option.name}
                    </option>
                  ))}
                </Select>
              </Col>
            )
          }
          {/* Lead Fields */
            values.policy_owner !== 'Yes' && (
              <Col xsmall={12} small={6} styles={styles.col}>
                <Select
                  label="Income Range:"
                  name="income_range"
                  id="nm-lead-form-income-range"
                  required
                  value={values.income_range}
                  onChange={(e) => {
                    handleChange(e);
                    handleAdobeDirectCall(e);
                  }}
                  onBlur={(e) => {
                    handleChange(e);
                  }}
                  disabled={isSubmitting}
                  error={errors.income_range}
                  errorMessage="Please select an income range.">
                  {incomes.map((option) => (
                    <option
                      key={option.name}
                      value={option.value}
                      disabled={option.disabled}>
                      {option.name}
                    </option>
                  ))}
                </Select>
              </Col>
            )
          }
          {/* Lead Fields */
            values.policy_owner !== 'Yes' && values.income_range === '39999' && (
              <Col xsmall={12} small={6} styles={styles.col}>
                <Select
                  label="My other assets add up to:"
                  name="wealth_range"
                  id="nm-lead-form-wealth-range"
                  required
                  value={values.wealth_range}
                  onChange={(e) => {
                    handleChange(e);
                    handleAdobeDirectCall(e);
                  }}
                  onBlur={(e) => {
                    handleChange(e);
                  }}
                  disabled={isSubmitting}
                  error={errors.wealth_range}
                  errorMessage="Please select a wealth range.">
                  {incomes.map((option) => (
                    <option
                      key={option.name}
                      value={option.value}
                      disabled={option.disabled}>
                      {option.name}
                    </option>
                  ))}
                </Select>
              </Col>
            )
          }
          {/* Service Request Fields */
            values.policy_owner === 'Yes' && (
              <Col xsmall={12} styles={styles.col}>
                <TextField
                  label="Tell us more about your needs..."
                  name="comments"
                  type="text"
                  id="nm-service-request-comments"
                  maxLength={2500}
                  required
                  value={values.comments}
                  onChange={handleChange}
                  onBlur={handleAdobeDirectCall}
                  disabled={isSubmitting}
                  multiline
                  rows={5}
                  error={errors.comments}
                  errorMessage="Please enter comments."
                />
              </Col>
            )
          }
          <Col xsmall={12} medium={6}>
            <RecaptchaInFormBadge />
          </Col>
          <Col xsmall={12}>
            <Button
              className='nmx-button--secondary' // adding legacy classes here to handle active state
              variant="secondary"
              theme={props.isPcg
                ? 'nmx-pcg'
                : null}
              type="submit"
              value="submit"
              id="profile-pages-nm-agent-form-submit-button"
              disabled={isSubmitting}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>

      <Recaptcha
        sitekey={recaptchaInvisible}
        size="invisible"
        callback={handleReCaptchaCallback}
        formLocation={formLocation}
        values={values}
      />
    </div>
  );
};

AgentFormComponent.propTypes = {
  /** application Id for the form */
  applicationId: PropTypes.string,
  agentnumber: PropTypes.string.isRequired,
  campaign: PropTypes.string.isRequired,
  /** customName is used for analytics to determine where the form submission is coming from */
  customName: PropTypes.string,
  formLocation: PropTypes.string.isRequired,
  id: PropTypes.string,
  isPcg: PropTypes.bool,
  /** This callback is used to tell parent component that the form has first been engaged with */
  onFormEngaged: PropTypes.func,
  recaptchaInvisible: PropTypes.string,
  source: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
  useRecaptcha: PropTypes.bool,
};

AgentFormComponent.defaultProps = {
  applicationId: 'FR-DIRECTORY', // set it to be FR-DIRECTORY by default
  id: 'nm-agent-form',
  isPcg: false,
  onFormEngaged: () => {},
  recaptchaInvisible: '<%=recaptchaInvisible%>',
  useRecaptcha: true,
};

export default AgentFormComponent;

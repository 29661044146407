import Location from '@nmx/utils/dist/utilities/frontend/location';
import registerComponent from './register_component';
// Import global analytics
import { navScript } from './helpers/nav.header';
// Grab UTM codes from the url and store them in the session so they can be utilized later for tracking purposes
import './session/utm';
// TODO: so much of this is junk. Will start with markup/styles first, then will come back to address JS/React
import NMXSearch from '../../components/page/Header/components/Search';
import NMUserLogin from './components/nm_user_login.component';
import NMLoginButton from './components/nm_login_button.component';
import NMExpandableToast from '../../components/modules/ExpandableToast';
import InformationalBanner from '../../components/modules/InformationalBanner';
import StickyBanner from '../../components/modules/StickyBanner';

navScript();

// https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid/2117523#2117523
// eslint-disable-next-line no-bitwise
const getGuid = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));

const preClientId = getGuid();
sessionStorage.setItem('pre-client-id', preClientId);
window.adobeDataLayer = window.adobeDataLayer || [];
window.adobeDataLayer.push({
  'user-details': { ids: { 'pre-client-id': preClientId } },
  page: { 'load-path': window.location.pathname.replace(/\/$/, '').toLowerCase() },
});
// Generate FB event ID to be sent along with FB conversion pixel events
const fbEventId = getGuid();
sessionStorage.setItem('fb-event-id', fbEventId);

XMLHttpRequest.prototype.open = (function (open) {
  // eslint-disable-next-line no-unused-vars
  return function (method, url, async) {
    // eslint-disable-next-line prefer-rest-params
    open.apply(this, arguments);
    if (url.indexOf('/nmx-api-proxy/nmx/') > 0) {
      this.setRequestHeader('X-Pre-Client-Id', preClientId);
    }
    if (url.indexOf('/nmx-api-proxy/nmx/ms-form-proxy/lead') > 0) {
      this.setRequestHeader('X-Fb-Event-Id', fbEventId);
      this.setRequestHeader('X-Prelead-Contact-Reference-Id', sessionStorage.getItem('prelead-contact-reference-id'));
    }
  };
}(XMLHttpRequest.prototype.open));

const preleadContactReferenceId = Location.getQueryParam('preleadContactReferenceId');
if (preleadContactReferenceId) {
  sessionStorage.setItem('prelead-contact-reference-id', preleadContactReferenceId);
}

registerComponent('nmx-search', NMXSearch, true);
registerComponent('nm-user-login', NMUserLogin);
registerComponent('nm-login-button', NMLoginButton);
registerComponent('nm-expandable-toast', NMExpandableToast);
registerComponent('nm-informational-banner', InformationalBanner, true);
registerComponent('nm-sticky-banner', StickyBanner, true);

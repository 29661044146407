/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DrawerCaretIcon from '../../icons/utility/DrawerCaret';
import DrawerCaretFlippedIcon from '../../icons/utility/DrawerCaretFlipped';
import Typography from '../../base/Typography';

import {
  DivItemContainerStyled,
  DtItemContainer,
  ButtonContainerStyled,
  DdItemContainer,
  TypographyTitleStyled,
  Styles,
} from './styles';

export const AccordionComponent = ({
  className,
  id,
  isFAQ,
  items,
  firstItemOpen,
  titleTypographyProps,
  toggleOpenIcon,
  toggleCloseIcon,
  variant,
}) => {
  const styles = Styles();
  const [accordionItems, setAccordionItems] = useState(items.map((i, index) => ({
    ...i,
    isOpen: !!(firstItemOpen && index === 0),
  }))); // keeps track of open Accordion indeces

  const ToggleOpenIconComponent = toggleOpenIcon;
  const ToggleCloseIconComponent = toggleCloseIcon;
  const accordionItemTitleTypographyVariant = variant === 'primary' ? 'p' : 'h3';
  const accordionItemTitleTypographyWeight = variant === 'primary' ? 400 : 500;
  const iconWidth = variant === 'primary' ? 15 : 30; // 30px height for FAQ +/- collapse/expand icon
  const iconHeight = variant === 'primary' ? 15 : 30; // 30px height for FAQ +/- collapse/expand icon

  return (
    <dl
      className={className}
      id={id}
      itemScope={isFAQ}
      {...isFAQ && { itemType: 'https://schema.org/FAQPage' }}>
      {accordionItems.map((item, index) => (
        <DivItemContainerStyled
          key={`${item.title}-${index}`}
          id={`container-${item.id}`}
          variant={variant}
          itemScope={isFAQ} {...isFAQ && { itemProp: 'mainEntity' }} {...isFAQ && { itemType: 'https://schema.org/Question' }}>
          <DtItemContainer id={`dt-container-${item.id}`}>
            <ButtonContainerStyled
              id={`dt-button-${item.id}`}
              variant={variant}
              onClick={() => {
                setAccordionItems(accordionItems.map((i, accordionIndex) => ({
                  ...i,
                  isOpen: index === accordionIndex ? !i.isOpen : i.isOpen,
                })));
              }}
              aria-expanded={item.isOpen ? 'true' : 'false'}
              isFAQ={isFAQ}
              isOpen={item.isOpen ? 'true' : 'false'}
              className={item.isOpen ? 'is-accordion-item-expanded' : ''}>
              {item.icon && <item.icon style={styles.TitleIconStyled} />}
              <TypographyTitleStyled
                disableBottomPadding
                variant={accordionItemTitleTypographyVariant}
                weight={accordionItemTitleTypographyWeight}
                component="h3"
                align="left"
                id={`dt-title-${item.id}`}
                {...titleTypographyProps}
                leftPadding={!!item.icon}
                {...isFAQ && { itemProp: 'name' }}>
                {item.title}
              </TypographyTitleStyled>
              {/* TODO: we can use one icon here and rotate for open closed. */}
              {item.isOpen ? (
                <ToggleCloseIconComponent
                  style={styles.IconToggleStyled}
                  height={iconHeight}
                  width={iconWidth}
                  className="accordion-toggle-icon"
                />
              ) : (
                <ToggleOpenIconComponent
                  style={styles.IconToggleStyled}
                  height={iconHeight}
                  width={iconWidth}
                  className="accordion-toggle-icon"
                />
              )}
            </ButtonContainerStyled>
          </DtItemContainer>
          <DdItemContainer
            variant={variant}
            style={{ display: item.isOpen ? 'block' : 'none' }}
            id={`dd-container-${item.id}`}
            {...isFAQ && { itemProp: 'acceptedAnswer' }}
            itemScope={isFAQ}
            {...isFAQ && { itemType: 'https://schema.org/Answer' }}
            isFAQ={isFAQ}
          >
            <Typography
              as={item.contentAs || 'p'}
              {...isFAQ && { itemProp: 'text' }}
              disableBottomPadding>
              {item.content}
            </Typography>
          </DdItemContainer>
        </DivItemContainerStyled>
      ))}
    </dl>
  );
};

AccordionComponent.propTypes = {
  /** optional additional className */
  className: PropTypes.string,
  /** Optional default open the first accordion item */
  firstItemOpen: PropTypes.bool,
  /**  */
  id: PropTypes.string.isRequired,
  /** boolean used to flag the insertion of schema microdata into the markup */
  isFAQ: PropTypes.bool,
  /** Array of accoladed to display in the section */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      /** Icon to display in front of title */
      icon: PropTypes.any,
      /** The 'id' of the accolade wanting to display */
      title: PropTypes.any.isRequired,
      /** The sup number corresponding with the footer reference, Required for descriptionListData needing source to cite */
      content: PropTypes.any.isRequired,
    }),
  ).isRequired,
  /** The icon displayed to toggle a drawer list item */
  toggleCloseIcon: PropTypes.any,
  /** The icon displayed to toggle a drawer list item */
  toggleOpenIcon: PropTypes.any,
  /** Any prop overrides to apply to the title text in the dt element */
  titleTypographyProps: PropTypes.object,
  /** default primary variant is white background, secondary has the gray background, used in FAQs */
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

AccordionComponent.defaultProps = {
  firstItemOpen: false,
  isFAQ: false,
  titleTypographyProps: {},
  toggleCloseIcon: DrawerCaretFlippedIcon,
  toggleOpenIcon: DrawerCaretIcon,
  variant: 'primary',
};

export default AccordionComponent;

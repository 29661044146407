import React, { useState, useEffect } from 'react';
import fixRecaptchaWindow from '@nmx/utils/dist/utilities/frontend/recaptcha_positioning';

import zIndex from '../../../theme/zIndex';

export const RecaptchaComponent = (props) => {
  const [recaptchaElm, setRecaptchaElm] = useState(undefined);
  const [response, setResponse] = useState(undefined);

  useEffect(() => {
    if (response) {
      props.callback(response);
    }
  }, [props.values, response]);

  const handleRecaptchaCallback = (recaptchaResponse) => {
    setResponse(recaptchaResponse);
  };

  const renderRecaptcha = () => {
    try {
      if (window.grecaptcha && window.grecaptcha.render) {
        window.grecaptcha.render(recaptchaElm, {
          sitekey: props.sitekey,
          size: props.size,
          callback: handleRecaptchaCallback,
        });
        fixRecaptchaWindow();
      } else {
        setTimeout(renderRecaptcha.bind(this), 50);
      }
    } catch (error) {
      console.error(`Error rendering recaptcha on ${props.formLocation}.`, error);
    }
  };

  useEffect(() => {
    // browser-only code - react-static has some server side rendering for build process
    if (recaptchaElm && typeof window !== 'undefined') {
      renderRecaptcha();
    }
  }, [recaptchaElm]);

  return (
    <div
      ref={(elm) => {
        setRecaptchaElm(elm);
      }}
      style={{
        zIndex: zIndex.recaptchaBadge,
        position: 'relative',
      }}
    />
  );
};

export default RecaptchaComponent;

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { CONSTANTS, events } from '@gelato/analytics-datalayer';
import Location from '@nmx/utils/dist/utilities/frontend/location';
import Session from '@nmx/utils/dist/utilities/frontend/session';
import {
  autoFormatPhone,
  isPhoneNumber,
} from '@nmx/utils/dist/utilities/validators';
import LeadService from '@nmx/utils/dist/services/LeadService/Frontend';
import { Container, Row } from '../../base/Grid';
import Form from '../../form/Form';
import LoadingModule from './LoadingModule';
import ThankYouModule from './ThankYouModule';
import ProgressBar from '../../base/ProgressBar';
import Colors from '../../theme/colors';
import { goals } from './utilities/inPageLeadFormData';

// Inputs
import AgeInput from './inputs/AgeInput';
import EmailInput from './inputs/EmailInput';
import FirstNameInput from './inputs/FirstNameInput';
import GoalCardRadioInput from './inputs/GoalCardRadioInput';
import GoalSelectInput from './inputs/GoalSelectInput';
import IncomeRangeSelectInput from './inputs/IncomeRangeSelectInput';
import LastNameInput from './inputs/LastNameInput';
import PhoneInput from './inputs/PhoneInput';
import WealthRangeSelectInput from './inputs/WealthRangeSelectInput';
import ZipInput from './inputs/ZipInput';

// custom icons
import {
  babyCarriageIcon,
  beachChairIcon,
  familyIcon,
  graduationIcon,
  growthChartIcon,
  homeIcon,
  shieldIcon,
  walletIcon,
  wordBubbleIcon,
} from './goalCardIcons/goalCardIcons';

// styles
import {
  ButtonBackStyled,
  ButtonNextStyled,
  ContainerGoalViewStyled,
  DivDynamicViewStyled,
  DivGoalCardsColStyled,
  DivInputColStyled,
  DivNextButtonContainerStyled,
  DivProgressBarContainerStyled,
  DivViewContainerStyled,
  IconBackButtonStyled,
  RowGoalCardsStyled,
  SectionStyled,
  TypographyBackButtonStyled,
} from './styles';

// Tooltips
import {
  AgeTooltip,
  EmailTooltip,
  FirstNameTooltip,
  GoalTooltip,
  IncomeRangeTooltip,
  LastNameTooltip,
  PhoneTooltip,
  WealthRangeTooltip,
  ZipTooltip,
} from './tooltips/tooltips';

// utilities
import {
  buildLeadPayload,
  initialAccessibilityObj,
  initialActiveViewsObj,
  validateCurrentInput,
  validateStep1,
  validateStep2,
  validateStep3,
  validateStep4,
} from './utilities/utilities';

// miscModules
import {
  embeddedHeaderRow,
  errorSubmittingMessage,
  getScreenNumber,
  heroContactInfoHeaderRow,
  heroDemographicHeaderRow,
  heroGoalsHeaderRow,
  heroPersonalInfoHeaderRow,
} from './MiscModules/miscModules';

const { EMBEDDED_FAFA } = CONSTANTS.NAME_LEAD_FORM_EXPERIENCES;

export const InPageLeadFormComponent = (props) => {
  const {
    config,
    formLocation,
    id,
    isPcg,
    onFormEngaged,
    specialization,
    variant,
  } = props;

  // REFS /////////////////////////////
  // These refs are used to target the text within the input labels
  // so that we can capture their computed width and position the tooltips dynamically.
  const goalInputLabelText = useRef();
  const ageInputLabelText = useRef();
  const zipInputLabelText = useRef();
  const incomeRangeInputLabelText = useRef();
  const wealthRangeInputLabelText = useRef();
  const firstNameInputLabelText = useRef();
  const lastNameInputLabelText = useRef();
  const emailInputLabelText = useRef();
  const phoneInputLabelText = useRef();

  // React Hooks ////////////////////////////////
  // ACTIVE VIEW SETTER
  const [view, setActiveView] = useState({ initialActiveViewsObj });

  // ACCESSIBLE VIEW SETTER
  const [accessibility, setViewAccessibility] = useState({ initialAccessibilityObj });

  // Goals cards
  const [goalCardsArray, setGoalCardsArray] = useState();
  const [goalInputsArray, setGoalInputsArray] = useState();
  const [onLoadSectionsArray, setOnLoadSectionsArray] = useState();
  const [onSubmitSectionsArray, setOnSubmitSectionsArray] = useState();

  const [values, setValues] = useState({
    goal: '',
    age: '',
    zip: '',
    incomeRange: '',
    wealthRange: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [errors, setErrors] = useState({
    goal: false,
    age: false,
    zip: false,
    incomeRange: false,
    wealthRange: false,
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
  });

  // Error messages are set by this component's native utilities.js validators.
  const [errorMessages, setErrorMessages] = useState({
    goal: '',
    age: '',
    zip: '',
    incomeRange: '',
    wealthRange: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [viewValidation, setViewValidation] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  });

  const [displayWealthRange, setDisplayWealthRange] = useState(false);
  const [backButtonTarget, setBackButtonTarget] = useState('step1');
  const [progressBarFillPercentage, setProgressBarFillPercentage] = useState(0);
  const [formIsValid, setFormIsValid] = useState(false);
  // Sets the position for the tooltips next to the end of each label
  const [goalInputLabelPos, setGoalInputLabelPos] = useState('');
  const [ageInputLabelPos, setAgeInputLabelPos] = useState('');
  const [zipInputLabelPos, setZipInputLabelPos] = useState('');
  const [incomeRangeInputLabelPos, setIncomeRangeInputLabelPos] = useState('');
  const [wealthRangeInputLabelPos, setWealthRangeInputLabelPos] = useState('');
  const [firstNameInputLabelPos, setFirstNameInputLabelPos] = useState('');
  const [lastNameInputLabelPos, setLastNameInputLabelPos] = useState('');
  const [emailInputLabelPos, setEmailInputLabelPos] = useState('');
  const [phoneInputLabelPos, setPhoneInputLabelPos] = useState('');
  const [allowTransition, setAllowTransition] = useState(true);

  // The fading transition for the views is currently 1000 ms
  // The accessibility transition needs to occur slightly afterwards.
  const accessibilityTransitionSpeed = 1100;

  // Accepts strings of the property accessors for the view and accessibility objects
  // and sets the correct views to be active.
  const activateViewTransition = (viewStep, accessibilityStep) => {
    setActiveView({
      ...initialActiveViewsObj,
      [viewStep]: true,
    });
    // NOW, we want to make isAccessibleStep1: true IMMEDIATELY, and allow the previous view to still be true...
    // DELAY...then make all OTHER accessibility fields FALSE

    // persists other current states and makes the next view and previous view to true
    setViewAccessibility({
      ...accessibility,
      [accessibilityStep]: true,
    });
    setTimeout(() => {
      // Sets all states to false accept for the new current view
      setViewAccessibility({
        ...initialAccessibilityObj,
        [accessibilityStep]: true,
      });
    }, accessibilityTransitionSpeed);
  };

  const analyticsPush = (e) => {
    const {
      // value,
      name,
    } = e.target;

    events.leadForms.interact({
      experience: EMBEDDED_FAFA,
      actionType: name,
      actionValues: 'complete',
    });
  };

  // Some sections get hidden and others display on submit.
  const setOtherPageSectionDisplays = () => {
    if (onSubmitSectionsArray) {
      for (let i = 0; i < onSubmitSectionsArray.length; i += 1) {
        onSubmitSectionsArray[i].style.display = 'block';
      }
    }
    if (onLoadSectionsArray) {
      for (let i = 0; i < onLoadSectionsArray.length; i += 1) {
        onLoadSectionsArray[i].style.display = 'none';
      }
    }
  };

  const setView = (step) => {
    if (allowTransition) {
      switch (step) {
        case 'step1':
          activateViewTransition('isActiveViewStep1', 'isAccessibleStep1');
          break;
        case 'step2':
          if (variant === 'hero') {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }
          activateViewTransition('isActiveViewStep2', 'isAccessibleStep2');
          break;
        case 'step3':
          activateViewTransition('isActiveViewStep3', 'isAccessibleStep3');
          break;
        case 'step4':
          activateViewTransition('isActiveViewStep4', 'isAccessibleStep4');
          break;
        case 'isSubmitting':
          activateViewTransition(
            'isActiveViewIsSubmitting',
            'isAccessibleIsSubmitting',
          );
          break;
        case 'thankYou':
          activateViewTransition('isActiveViewThankYou', 'isAccessibleThankYou');
          break;
        case 'error':
          activateViewTransition('isActiveViewError', 'isAccessibleError');
          break;
        default:
        // do nothing
      }
      setAllowTransition(false);
      setTimeout(() => {
        setAllowTransition(true);
      }, accessibilityTransitionSpeed);
    }
  };

  // EVENT HANDLERS ///////////////////////////////////////
  const handleChange = (e) => {
    // if goals card
    if (e.target.getAttribute('data-type') === 'goals-card') {
      if (goalCardsArray !== undefined && goalCardsArray !== null) {
        for (let i = 0; i < goalCardsArray.length; i += 1) {
          goalCardsArray[i].classList.remove('is-active');
          goalInputsArray[i].setAttribute('aria-checked', false);
        }
      }
      e.target.parentNode.classList.add('is-active');
      e.target.setAttribute('aria-checked', true);

      analyticsPush(e);
    }
    // validate individual fields as they are filled out
    const currentInputIsValid = validateCurrentInput(e, values.incomeRange);
    // Set error states for inputs in real-time.
    // If a field has an error, set the boolean to display the error tooltip.
    setErrors({
      ...errors,
      [e.target.name]: !currentInputIsValid[0],
    });
    // If there are errors, set the appropriate error message returned from the validators.
    setErrorMessages({
      ...errorMessages,
      [e.target.name]: currentInputIsValid[1],
    });
    // Set input values, used for display and in the payload for submission.
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    // Inform parent component of engagement with form
    onFormEngaged();
  };

  const handlePhoneKeyUp = (e) => {
    // try to format the phone number on keyUp
    const phoneInput = e.target.value;
    // If the formatted num ends up being valid
    if (isPhoneNumber(phoneInput)) {
      const formattedPhoneInput = autoFormatPhone(phoneInput);
      setValues({
        ...values,
        phone: formattedPhoneInput,
      });
    }
  };

  const handleReCaptchaCallback = (recaptchaReponseToken) => {
    let request = null;
    let payload = null;
    const campaignName
      = Location.getQueryParam('campName') || 'NM.com In-page lead Form';
    const agentNumber = Session.get('agentNumber') || '';

    payload = buildLeadPayload(
      values,
      agentNumber,
      campaignName,
      recaptchaReponseToken,
    );
    payload.is_recaptcha_v3 = true; // Moving this lead form to v3 recaptcha
    if (specialization) {
      // If algo sublist filter specialization is passed in, feed to API
      payload.specialization = specialization;
    }

    request = LeadService.submitLead(payload);

    try {
      request
        .then(() => {
          // Success!
          setView('thankYou');
          setOtherPageSectionDisplays();
          events.leadForms.complete({ experience: EMBEDDED_FAFA });
        })
        .catch((error) => {
          setView('error');
          console.error(`Error submitting lead on ${formLocation}.`, error);
        });
    } catch (error) {
      setView('error');
      console.error(`Error submitting lead on ${formLocation}.`, error);
      events.leadForms.completeFailure({
        experience: EMBEDDED_FAFA,
        errorMsg: `Unable to submit in-page lead form with error ${error}`,
      });
    }
  };

  // call this function when we've validated the form and are ready to submit
  const executeRecaptchaAndSubmit = () => {
    setView('isSubmitting');
    // Wait 1100ms to allow submitting view to fully transition in before going to confirmation view
    setTimeout(() => {
      try {
      // recaptchaV3 method for executing
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(config.public.recaptchaV3, { action: 'submit' }).then((token) => {
            document
              .querySelectorAll('.recaptchaResponse')
              // eslint-disable-next-line no-param-reassign
              .forEach((elem) => { elem.value = token; });
            handleReCaptchaCallback(token);
          });
        });
      } catch (error) {
        // if recaptcha execute fails
        setView('error');
        console.error(`Error executing recaptcha at form submit at path: ${window.location.pathname}.`, error);
      }
    }, accessibilityTransitionSpeed);
  };

  // call this function when we've validated the form and there's an error
  const resetRecaptcha = () => {
    try {
      window.grecaptcha.ready(() => {
        // recaptchaV3 method for executing
        window.grecaptcha.execute(config.public.recaptchaV3, { action: 'reset' })
          .then((token) => {
            document
              .querySelectorAll('.recaptchaResponse')
              // eslint-disable-next-line no-param-reassign
              .forEach((elem) => { elem.value = token; });
          });
      });
    } catch (error) {
      // if recaptcha execute fails, show error
      setView('error');
      console.error(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // final check for errors
    setFormIsValid(
      viewValidation.step1
        && viewValidation.step2
        && viewValidation.step3
        && viewValidation.step4,
    );

    if (formIsValid) {
      executeRecaptchaAndSubmit();
    } else {
      resetRecaptcha();
    }
  };

  // useEffect HOOKS ////////////////////////////////////
  useEffect(() => {
    setView('step1'); // <<<< Change the initial view for testing/development here ( step1 is the original setting )
    setGoalCardsArray(document.querySelectorAll('.fafa-hero-goal-card'));
    setGoalInputsArray(
      document.querySelectorAll('.fafa-hero-goal-card-radio-input'),
    );
    setOnLoadSectionsArray(document.querySelectorAll('.js-hide-on-submit'));
    setOnSubmitSectionsArray(document.querySelectorAll('.js-show-on-submit'));

    // get input label width (with refs) so we can dynamically position the tooltips (with state vars)
    if (variant !== 'hero') {
      setGoalInputLabelPos(
        goalInputLabelText.current.getBoundingClientRect().width,
      );
    }
    setAgeInputLabelPos(ageInputLabelText.current.getBoundingClientRect().width);
    setZipInputLabelPos(zipInputLabelText.current.getBoundingClientRect().width);
    setIncomeRangeInputLabelPos(
      incomeRangeInputLabelText.current.getBoundingClientRect().width,
    );
    setFirstNameInputLabelPos(
      firstNameInputLabelText.current.getBoundingClientRect().width,
    );
    setLastNameInputLabelPos(
      lastNameInputLabelText.current.getBoundingClientRect().width,
    );
    setEmailInputLabelPos(
      emailInputLabelText.current.getBoundingClientRect().width,
    );
    setPhoneInputLabelPos(
      phoneInputLabelText.current.getBoundingClientRect().width,
    );

    events.leadForms.load({ experience: EMBEDDED_FAFA });
  }, []);

  useEffect(() => {
    // Hiding other sections of the FAFA page on load. They will display again on submit.
    if (onSubmitSectionsArray) {
      for (let i = 0; i < onSubmitSectionsArray.length; i += 1) {
        onSubmitSectionsArray[i].style.display = 'none';
      }
    }
  }, [onSubmitSectionsArray]);

  // Updates progressBar everytime the view updates
  useEffect(() => {
    if (view.isActiveViewStep1) {
      setProgressBarFillPercentage(0);
      setBackButtonTarget('');
    } else if (view.isActiveViewStep2) {
      setProgressBarFillPercentage(33);
      setBackButtonTarget('step1');
    } else if (view.isActiveViewStep3) {
      setProgressBarFillPercentage(66);
      setBackButtonTarget('step2');
    } else if (view.isActiveViewStep4) {
      setProgressBarFillPercentage(100);
      setBackButtonTarget('step3');
    } else {
      setBackButtonTarget('');
    }
  }, [view]);

  // position tooltip for wealthRange when it displays
  useEffect(() => {
    if (displayWealthRange) {
      setWealthRangeInputLabelPos(
        wealthRangeInputLabelText.current.getBoundingClientRect().width,
      );
    }
  }, [displayWealthRange]);

  // As inputs are filled out, validate each field and check if the view is valid and complete
  useEffect(() => {
    // only validate steps if they are active
    if (view.isActiveViewStep1) {
      // STEP 1: If all fields are valid, set view as valid and activate next button
      setViewValidation({
        ...viewValidation,
        step1: validateStep1(values.goal),
      });
    } else if (view.isActiveViewStep2) {
      // Display wealth range input if low incomeRange
      setDisplayWealthRange(values.incomeRange && values.incomeRange <= 39999);
      // STEP 2: If all fields are valid, set view as valid and activate next button
      setViewValidation({
        ...viewValidation,
        step2: validateStep2(
          values.age,
          values.zip,
          values.incomeRange,
          values.wealthRange,
        ),
      });
    } else if (view.isActiveViewStep3) {
      // STEP 3: If all fields are valid, set view as valid and activate next button
      setViewValidation({
        ...viewValidation,
        step3: validateStep3(values.firstName, values.lastName),
      });
    } else if (view.isActiveViewStep4) {
      // STEP 4: If all fields are valid, set view as valid and activate submit button
      setViewValidation({
        ...viewValidation,
        step4: validateStep4(values.email, values.phone),
      });
    }
  }, [values]);

  useEffect(() => {
    setFormIsValid(
      viewValidation.step1
        && viewValidation.step2
        && viewValidation.step3
        && viewValidation.step4,
    );
  }, [viewValidation.step4]);

  return (
    <SectionStyled
      id={id}
      moduleName="in-page-lead-form-module"
      moduleVariation="A"
      isPcg={isPcg}>
      <DivProgressBarContainerStyled
        isActive={
          view.isActiveViewStep1
          || view.isActiveViewStep2
          || view.isActiveViewStep3
          || view.isActiveViewStep4
        }>
        <ProgressBar
          fillPercentage={progressBarFillPercentage}
          fillColor={isPcg ? 'pcg-gold-1' : 'light-blue-2'}
          bgColor={isPcg ? 'neutral-gray-58' : 'light-blue-3'}
        />
      </DivProgressBarContainerStyled>
      <Form
        id={`${id}-form`}
        legend="Find a financial advisor form"
        onSubmit={handleSubmit}>
        <DivViewContainerStyled
          views={view}
          variant={variant}
          displayWealthRange={displayWealthRange}
          errors={errors}>
          {backButtonTarget && (
            <ButtonBackStyled
              id="in-page-lead-form-back-button"
              variant="quarternary"
              onClick={() => {
                setView(backButtonTarget);
              }}>
              <IconBackButtonStyled className="js-in-page-lead-animated-back-button-icon" />
              <TypographyBackButtonStyled color={Colors.neutral.white}>
                Back
              </TypographyBackButtonStyled>
            </ButtonBackStyled>
          )}
          {/* STEP 1 //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewStep1}
            aria-hidden={!view.isActiveViewStep1}
            isAccessible={accessibility.isAccessibleStep1}
            isPcg={isPcg}>
            <ContainerGoalViewStyled
              id="in-page-lead-financial-goal-info-view"
              variant={variant}>
              {getScreenNumber(1)}
              {variant === 'hero'
                ? heroGoalsHeaderRow
                : embeddedHeaderRow(false, 'h2', isPcg)}
              {/* Only shows on the 'hero' variant of the form */}
              {variant === 'hero' && (
                <>
                  <RowGoalCardsStyled align="center">
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-buy-a-home"
                        icon={homeIcon()}
                        value={goals[1].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-save-for-college"
                        icon={graduationIcon()}
                        value={goals[2].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-save-for-retirement"
                        icon={beachChairIcon()}
                        value={goals[3].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-protect-my-income"
                        icon={shieldIcon()}
                        value={goals[4].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-plan-for-my-growing-family"
                        icon={babyCarriageIcon()}
                        value={goals[5].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-protect-my-family"
                        icon={familyIcon()}
                        value={goals[6].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-grow-my-business"
                        icon={growthChartIcon()}
                        value={goals[7].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-grow-my-investments"
                        icon={walletIcon()}
                        value={goals[8].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled>
                      <GoalCardRadioInput
                        id="fafa-interactive-flow-goal-card-im-not-sure"
                        icon={wordBubbleIcon()}
                        value={goals[9].name}
                        onChange={handleChange}
                      />
                    </DivGoalCardsColStyled>
                    <DivGoalCardsColStyled />
                  </RowGoalCardsStyled>
                </>
              )}
              <Row align="center">
                {/* FINANCIAL GOAL SELECT DROPDOWN //////////////////////////////////////////////////////////////////// */}
                {variant !== 'hero' && (
                  <DivInputColStyled inputWidth={390}>
                    <GoalSelectInput
                      value={values.goal}
                      onBlur={analyticsPush}
                      onChange={handleChange}
                      error={errors.goal}
                      errorMessage={errorMessages.goal}
                      ref={goalInputLabelText}
                      isPcg={isPcg}
                    />
                    <GoalTooltip
                      isPcg={isPcg}
                      leftPos={goalInputLabelPos} />
                  </DivInputColStyled>
                )}
                <DivNextButtonContainerStyled variant={variant}>
                  <ButtonNextStyled
                    themeType={isPcg && 'nmx-pcg'}
                    id="in-page-lead-step-1-next-button"
                    variant="primary"
                    disabled={!viewValidation.step1}
                    aria-label="Proceed to step 2"
                    formvariant={variant}
                    onClick={() => {
                      setView('step2');
                    }}>
                    Next
                  </ButtonNextStyled>
                </DivNextButtonContainerStyled>
              </Row>
            </ContainerGoalViewStyled>
          </DivDynamicViewStyled>
          {/* STEP 2 //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewStep2}
            aria-hidden={!view.isActiveViewStep2}
            isAccessible={accessibility.isAccessibleStep2}
            isPcg={isPcg}>
            <Container id="in-page-lead-demographic-info-view">
              {getScreenNumber(2)}
              {variant === 'hero'
                ? heroDemographicHeaderRow
                : embeddedHeaderRow(true, 'p', isPcg)}
              <Row align="center">
                {/* AGE INPUT //////////////////////////////////////////////////////////////////// */}
                <DivInputColStyled inputWidth={140}>
                  <AgeInput
                    value={values.age}
                    onBlur={analyticsPush}
                    onChange={handleChange}
                    error={errors.age}
                    errorMessage={errorMessages.age}
                    ref={ageInputLabelText}
                    isPcg={isPcg}
                  />
                  <AgeTooltip
                    isPcg={isPcg}
                    leftPos={ageInputLabelPos} />
                </DivInputColStyled>
                {/* ZIP CODE INPUT //////////////////////////////////////////////////////////////////// */}
                <DivInputColStyled inputWidth={160}>
                  <ZipInput
                    value={values.zip}
                    onBlur={analyticsPush}
                    onChange={handleChange}
                    error={errors.zip}
                    errorMessage={errorMessages.zip}
                    ref={zipInputLabelText}
                    isPcg={isPcg}
                  />
                  <ZipTooltip
                    isPcg={isPcg}
                    leftPos={zipInputLabelPos} />
                </DivInputColStyled>
                {/* INCOME RANGE SELECT DROPDOWN //////////////////////////////////////////////////////////////////// */}
                <DivInputColStyled inputWidth={235}>
                  <IncomeRangeSelectInput
                    value={values.incomeRange}
                    onBlur={analyticsPush}
                    onChange={handleChange}
                    error={errors.incomeRange}
                    errorMessage={errorMessages.incomeRange}
                    ref={incomeRangeInputLabelText}
                    isPcg={isPcg}
                  />
                  <IncomeRangeTooltip
                    isPcg={isPcg}
                    leftPos={incomeRangeInputLabelPos} />
                </DivInputColStyled>
                {/* WEALTH RANGE SELECT DROPDOWN //////////////////////////////////////////////////////////////////// */}
                {displayWealthRange && (
                  <DivInputColStyled inputWidth={225}>
                    <WealthRangeSelectInput
                      value={values.wealthRange}
                      onBlur={analyticsPush}
                      onChange={handleChange}
                      error={errors.wealthRange}
                      errorMessage={errorMessages.wealthRange}
                      ref={wealthRangeInputLabelText}
                      isPcg={isPcg}
                    />
                    <WealthRangeTooltip
                      isPcg={isPcg}
                      leftPos={wealthRangeInputLabelPos} />
                  </DivInputColStyled>
                )}
                <DivNextButtonContainerStyled variant={variant}>
                  <ButtonNextStyled
                    themeType={isPcg && 'nmx-pcg'}
                    id="in-page-lead-step-2-next-button"
                    variant="primary"
                    disabled={!viewValidation.step2}
                    aria-label="Proceed to step 3"
                    formvariant={variant}
                    onClick={() => {
                      setView('step3');
                    }}>
                    Next
                  </ButtonNextStyled>
                </DivNextButtonContainerStyled>
              </Row>
            </Container>
          </DivDynamicViewStyled>
          {/* STEP 3 //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewStep3}
            aria-hidden={!view.isActiveViewStep3}
            isAccessible={accessibility.isAccessibleStep3}
            isPcg={isPcg}>
            <Container id="in-page-lead-personal-info-view">
              {getScreenNumber(3)}
              {variant === 'hero'
                ? heroPersonalInfoHeaderRow
                : embeddedHeaderRow(true, 'p', isPcg)}
              <Row align="center">
                {/* FIRST NAME INPUT //////////////////////////////////////////////////////////////////// */}
                <DivInputColStyled inputWidth={260}>
                  <FirstNameInput
                    value={values.firstName}
                    onBlur={analyticsPush}
                    onChange={handleChange}
                    error={errors.firstName}
                    errorMessage={errorMessages.firstName}
                    ref={firstNameInputLabelText}
                    isPcg={isPcg}
                  />
                  {variant !== 'hero' && (
                    <FirstNameTooltip
                      isPcg={isPcg}
                      leftPos={firstNameInputLabelPos} />
                  )}
                </DivInputColStyled>
                {/* LAST NAME INPUT //////////////////////////////////////////////////////////////////// */}
                <DivInputColStyled inputWidth={260}>
                  <LastNameInput
                    value={values.lastName}
                    onBlur={analyticsPush}
                    onChange={handleChange}
                    error={errors.lastName}
                    errorMessage={errorMessages.lastName}
                    ref={lastNameInputLabelText}
                    isPcg={isPcg}
                  />
                  {variant !== 'hero' && (
                    <LastNameTooltip
                      isPcg={isPcg}
                      leftPos={lastNameInputLabelPos} />
                  )}
                </DivInputColStyled>
                <DivNextButtonContainerStyled variant={variant}>
                  <ButtonNextStyled
                    themeType={isPcg && 'nmx-pcg'}
                    id="in-page-lead-step-3-next-button"
                    variant="primary"
                    disabled={!viewValidation.step3}
                    aria-label="Proceed to step 4"
                    formvariant={variant}
                    onClick={() => {
                      setView('step4');
                    }}>
                    Next
                  </ButtonNextStyled>
                </DivNextButtonContainerStyled>
              </Row>
            </Container>
          </DivDynamicViewStyled>
          {/* STEP 4 //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewStep4}
            aria-hidden={!view.isActiveViewStep4}
            isAccessible={accessibility.isAccessibleStep4}
            isPcg={isPcg}>
            <Container id="in-page-lead-contact-info-view">
              {getScreenNumber(4)}
              {variant === 'hero'
                ? heroContactInfoHeaderRow
                : embeddedHeaderRow(true, 'p', isPcg)}
              <Row align="center">
                {/* EMAIL INPUT //////////////////////////////////////////////////////////////////// */}
                <DivInputColStyled inputWidth={260}>
                  <EmailInput
                    value={values.email}
                    onBlur={analyticsPush}
                    onChange={handleChange}
                    error={errors.email}
                    errorMessage={errorMessages.email}
                    ref={emailInputLabelText}
                    isPcg={isPcg}
                  />
                  {variant !== 'hero' && (
                    <EmailTooltip
                      isPcg={isPcg}
                      leftPos={emailInputLabelPos} />
                  )}
                </DivInputColStyled>
                {/* PHONE INPUT //////////////////////////////////////////////////////////////////// */}
                <DivInputColStyled inputWidth={260}>
                  <PhoneInput
                    value={values.phone}
                    onBlur={analyticsPush}
                    onChange={handleChange}
                    onKeyUp={handlePhoneKeyUp}
                    error={errors.phone}
                    errorMessage={errorMessages.phone}
                    ref={phoneInputLabelText}
                    isPcg={isPcg}
                  />
                  {variant !== 'hero' && (
                    <PhoneTooltip
                      isPcg={isPcg}
                      leftPos={phoneInputLabelPos} />
                  )}
                </DivInputColStyled>
                <DivNextButtonContainerStyled variant={variant}>
                  <ButtonNextStyled
                    themeType={isPcg && 'nmx-pcg'}
                    id="in-page-lead-step-submit-button"
                    variant="primary"
                    type="submit"
                    disabled={!formIsValid}
                    formvariant={variant}
                    aria-label="Submit form">
                    Submit
                  </ButtonNextStyled>
                </DivNextButtonContainerStyled>
              </Row>
            </Container>
          </DivDynamicViewStyled>
          {/* IS SUBMITTING //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewIsSubmitting}
            aria-hidden={!view.isActiveViewIsSubmitting}
            isAccessible={accessibility.isAccessibleIsSubmitting}
            centered={true}
            isPcg={isPcg}>
            <LoadingModule isPcg={isPcg}/>
          </DivDynamicViewStyled>
          {/* THANK YOU //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewThankYou}
            aria-hidden={!view.isActiveViewThankYou}
            isAccessible={accessibility.isAccessibleThankYou}
            centered={true}
            isPcg={isPcg}>
            <ThankYouModule
              isPcg={isPcg}
              userName={values.firstName}
              variant={variant} />
          </DivDynamicViewStyled>
          {/* ERROR //////////////////////////////// */}
          <DivDynamicViewStyled
            isActive={view.isActiveViewError}
            aria-hidden={!view.isActiveViewError}
            isAccessible={accessibility.isAccessibleError}
            centered={true}
            isPcg={isPcg}>
            {errorSubmittingMessage}
          </DivDynamicViewStyled>
        </DivViewContainerStyled>
      </Form>
      <input
        type='hidden'
        name='recaptcha_response'
        className='recaptchaResponse' />
    </SectionStyled>
  );
};

InPageLeadFormComponent.propTypes = {
  id: PropTypes.string.isRequired,
  formLocation: PropTypes.string.isRequired,
  config: PropTypes.shape({ public: PropTypes.shape({ recaptchaInvisible: PropTypes.string }).isRequired }),
  /** This callback is used to tell parent component that the form has first been engaged with */
  onFormEngaged: PropTypes.func,
  variant: PropTypes.string.isRequired,
  /** Specialization to be passed into the leads API and used in the algo sublist rep filtering logic */
  specialization: PropTypes.oneOf(['SPECIAL_NEEDS']),
};

InPageLeadFormComponent.defaultProps = {
  // Neccesarry to allow legacy apps to capture the configs with string replace
  config: { public: { recaptchaInvisible: '<%=recaptchaInvisible%>' } },
  onFormEngaged: () => {},
  variant: 'embedded',
};

export default InPageLeadFormComponent;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import find from 'lodash/find';
// template components
import AssistiveText from '../../../../base/AssistiveText';
import Form from '../../../../form/Form';
import Link from '../../../../base/Link';
import { Row, Col } from '../../../../base/Grid';
import TextField from '../../../../form/TextField';
import Typography from '../../../../base/Typography';
// icon imports
import CloseIcon from '../../../../icons/utility/Close';
import EyeUtility from '../../../../icons/utility/Eye';
import EyeWithSlashUtility from '../../../../icons/utility/EyeWithSlash';
import ErrorNotificationFillIcon from '../../../../icons/utility/ErrorNotificationFill';

import {
  ButtonCloseStyled,
  ButtonLoginStyled,
  ButtonHideShowPasswordStyled,
  ColFormStyled,
  ColLoginContentStyled,
  ColStyledAppDownloadContainer,
  ColStyledDownloadLinkContainer,
  ContainerModalStyled,
  DivLoginErrorStyled,
  DivModalContainerStyled,
  DivModalOverlayStyled,
  LinkStyled,
  ListItemStyledDownLoadLink,
  RowStyled,
  TypographyStyled,
  UlLoginLinksStyled,
} from './styles';

const checkDeviceType = () => (('ontouchstart' in window)
  || (navigator.maxTouchPoints > 0)
  || (navigator.msMaxTouchPoints > 0) ? 'mobile' : 'desktop');

export const UserLoginModalComponent = (props) => {
  // states
  const [altHeight, setAltHeight] = useState('');
  const [altModalHeight, setAltModalHeight] = useState('');
  const [announcementIsActive, setAnnouncementIsActive] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [password, setPassword] = useState('');
  const [showHidePasswordChecked, setChecked] = useState(false);
  const [username, setUsername] = useState('');
  // refs
  const loginComponent = useRef();
  const modalCloseEl = useRef();
  const modalWindow = useRef();
  const overlay = useRef();

  // functions
  const handleHideShowPasswordChange = () => {
    setChecked(!showHidePasswordChecked);
    return false;
  };

  const closeModal = () => {
    // Using the .remove method instead of using state because the nm_mobile_login_button
    // component is adding the is-active class to this component in order to display it.
    // This was a simpler way of achieving the functionality without having to implement the context API or Redux.
    loginComponent.current.classList.remove('is-active');
    document.body.classList.remove('modal-is-open');
    document.getElementById('nmx-login-open-button').focus();
  };

  const checkModalHeight = () => {
    if (modalWindow !== undefined && modalWindow !== null && modalWindow.current !== null) { // TODO: use state.isOpen?
      return modalWindow.current.offsetHeight; // totalModalHeight
    }
    return false;
  };

  const keyDownListener = (e) => {
    const tabKeyCode = 9;
    const escapeKeyCode = 27;
    const enterKeyCode = 13;

    switch (e.keyCode) {
      case escapeKeyCode:
      // close modal on escape key press
        closeModal();
        break;
      case enterKeyCode:
      // toggle search modal on enter key press
        if (document.activeElement === modalCloseEl.current) {
          e.preventDefault();
          closeModal();
        }
        break;
      case tabKeyCode:
      // if modal is open, trap focus
      // TODO: this is used in a few places (search, login modal)... add as a helper function?
        if (isOpen === true) {
          const thisModal = modalWindow.current; // document.getElementById('nmx-client-login-modal')
          thisModal.focus();
          const focusableEls = Array.prototype.slice.call(thisModal.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'));
          const firstFocusableEl = focusableEls[0];
          const lastFocusableEl = focusableEls[focusableEls.length - 1];
          // if modal does not have focus, focus on modal, otherwise, focus on first focusable ele
          if (find(focusableEls, document.activeElement) === undefined) {
            firstFocusableEl.focus();
          }

          if (e.shiftKey) {
          // reverse tab
            if (document.activeElement === firstFocusableEl) {
              e.preventDefault();
              lastFocusableEl.focus();
            }
          } else if (document.activeElement === lastFocusableEl) {
          // forward tab
            e.preventDefault();
            firstFocusableEl.focus();
          }
        }
        break;
      default:
        break;
    }
  };

  const setModalAttributes = () => {
    const totalModalHeight = checkModalHeight();
    if (isOpen) {
      document.body.classList.add('modal-is-open'); // used for scroll lock // TODO: we're getting legacy styles in repos overriding body.overflow styles, remove the html styles once figured out.
    }

    if ((typeof document !== 'undefined') && (checkDeviceType() === 'mobile')) {
      // mobile attributes
      setAltHeight(window.innerHeight);
      setAltModalHeight('100%');
    } else {
      // desktop attributes
      // desktop state, content height greater than modal height
      setAltHeight(`${window.innerHeight}px`);
      setAltModalHeight(window.innerHeight <= totalModalHeight ? `${window.innerHeight}px` : 'auto');
    }
  };

  const resizeListener = () => {
    if (modalWindow !== undefined && modalWindow !== null) {
      // set modal attributes
      setModalAttributes();
    }
  };

  // Component mount
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // Get data from localStorage to see if the user chose to hide the announcement component
    try {
      if ((typeof localStorage !== 'undefined') && localStorage.getItem('announcementIsActive') === 'false') {
        setAnnouncementIsActive(false);
      }
    } catch (error) {
      console.error(`localStorage getItem failed with error ${error}`);
    }

    if (typeof window !== 'undefined') {
      // browser-only code - react-static has some server side rendering for build process
    // add keydown listener
      window.addEventListener('keydown', throttle(keyDownListener, 100), true);
      // add resize listener
      window.addEventListener('resize', debounce(resizeListener, 100), true);

      overlay.current.addEventListener('click', () => { // TODO: add Component listener and throttle
        closeModal();
      });

      // Use MutationObserver to detect class change on parent Component (this is only needed because button and modal are in 2 separate Components)
      // TODO: rework button and modal Components to utilize simpler React f
      // unctionality: state, etc... // This method was just to streamline currently 2 separate components (button, modal)
      const observerConfig = {
        attributes: true,
        attributeFilter: ['class'],
        subtree: false,
      };

      // When a mutation is observed
      const observerCallback = (mutationsList) => {
        mutationsList.forEach((mutation) => {
        // If active, set component height (so flexbox functionality works)
          if (mutation.target.classList.contains('is-active')) {
            mutation.target.style.height = `${window.innerHeight}px`; // modal component (includes overlay)
            // TODO: once login component is using Component-based states, rework this initial height setting (mutationObserver probably won't be necessary)
            mutation.target.childNodes[0].style.height = window.innerHeight <= mutation.target.childNodes[0].offsetHeight
              ? `${window.innerHeight}px`
              : `${mutation.target.childNodes[0].offsetHeight}px`; // modal window
            setIsOpen(true);
            document.body.classList.add('modal-is-open');
          } else {
            mutation.target.style.height = 0; // TODO: confirm this is necessary? or just leave set height
            setIsOpen(false);
            document.body.classList.remove('modal-is-open');
          }
        });
      };

      // Create an new observer
      const observer = new MutationObserver(observerCallback);

      // Start observing
      observer.observe(loginComponent.current, observerConfig);

      return function cleanupListener() {
        window.removeEventListener('keydown', throttle(keyDownListener, 100), true);
        window.removeEventListener('resize', debounce(resizeListener, 100), true);
      };
    }
  }, []);

  const validateForm = (e) => {
    e.preventDefault();
    if (!username || !password) {
      setLoginError(true);
    } else {
      setLoginError(false);
      const formEl = document.getElementById('nmx-client-login-form');
      formEl.submit();
    }
  };

  return (
    <DivModalContainerStyled
      className='nmx-client-login'
      id='nmx-client-login'
      ref={loginComponent}
      style={{ height: altHeight }}
    >
      <ContainerModalStyled
        className={classNames('nmx-modal nmx-client-login-modal', { 'announcement-is-active': announcementIsActive })}
        id='nmx-client-login-modal'
        role='dialog'
        aria-modal='true'
        ref={modalWindow}
        style={{ height: altModalHeight }}
      >
        <Row>
          <ColLoginContentStyled
            xsmall={12}>
            <ButtonCloseStyled
              id='nmx-client-login-modal-close-button'
              onClick={closeModal}
              ref={modalCloseEl}>
              <CloseIcon />
              <AssistiveText text='Close Login Window' />
            </ButtonCloseStyled>
            <Typography
              component='h4'
              variant='h3'
              align='center'>
              Log in
            </Typography>
            {
              loginError && (
                <DivLoginErrorStyled>
                  <ErrorNotificationFillIcon />
                  <Typography
                    align='center'
                    disableBottomPadding >
                    Please fill in your Username and Password.
                  </Typography>
                </DivLoginErrorStyled>
              )
            }
            <Form
              className='nmx-form nmx-client-login-modal__form'
              method='POST'
              id='nmx-client-login-form'
              legend='Enter your username and password to access your account.'
              action={props.config.services.loginPageUrl}>
              <fieldset>
                <Row>
                  <ColFormStyled xsmall={12}>
                    <TextField
                      label='Username'
                      id='nmx-client-login-username'
                      name='username'
                      type='text'
                      required={true}
                      value={username}
                      onChange={(e) => { setUsername(e.target.value); }}
                      onBlur={(e) => { setUsername(e.target.value.trim()); }}
                    />
                  </ColFormStyled>
                  <ColFormStyled xsmall={12}>
                    <TextField
                      label='Password'
                      id='nmx-client-login-password'
                      name='password'
                      type={ showHidePasswordChecked ? 'text' : 'password'}
                      required={true}
                      value={password}
                      onChange={(e) => { setPassword(e.target.value); }}
                    />
                    <ButtonHideShowPasswordStyled type='button' onClick={handleHideShowPasswordChange}>
                      <span className='nmx-icon__container'>
                        {showHidePasswordChecked
                          ? <EyeUtility />
                          : <EyeWithSlashUtility />
                        }
                      </span>
                    </ButtonHideShowPasswordStyled>
                  </ColFormStyled>
                </Row>
                <Row flex-direction='row'>
                  <ColFormStyled xsmall={12}>
                    <TypographyStyled>Forgot <LinkStyled href={`${props.config.services.loginPageUrlBase}/forgot-username`} ariaLabel='Help with finding your username'>username</LinkStyled> or <LinkStyled href={`${props.config.services.loginPageUrlBase}/password-reset`} ariaLabel='Help with resetting your password'>password</LinkStyled>&#63;</TypographyStyled>
                  </ColFormStyled>
                </Row>
                <Row flex-direction='row'>
                  <ColFormStyled xsmall={12}>
                    <ButtonLoginStyled
                      id='nmx-client-login-submit'
                      className='nmx-client-login-modal__submit'
                      type='submit'
                      variant='secondary'
                      onClick={validateForm}>
                      Log in
                    </ButtonLoginStyled>
                    <Typography align='center'>
                      Don&#39;t have an account&#63; <Link href={`${props.config.services.loginPageUrlBase}/registration`} ariaLabel='Register for a new account.'>Register</Link>.
                    </Typography>
                  </ColFormStyled>
                </Row>
              </fieldset>
            </Form>
            <Row>
              <Col align='center'>
                <Typography disableBottomPadding>
                  Log in to other Northwestern Mutual sites
                </Typography>
                <UlLoginLinksStyled className='reduced'>
                  <li className='link-list__item'>
                    <Link
                      href='https://www.eaccountservices.com/NM/'
                      ariaLabel='Visit Northwestern Access Fund.'>
                      Access Fund
                    </Link>
                  </li>
                  <li className='link-list__item'>
                    <Link
                      href='https://bcis.northwesternmutual.com/bcis/update/login'
                      ariaLabel='Visit Northwestern Mutual Business Insurance.'>
                      Business Insurance
                    </Link>
                  </li>
                </UlLoginLinksStyled>
              </Col>
            </Row>
          </ColLoginContentStyled>
        </Row>
        <RowStyled>
          <ColStyledAppDownloadContainer>
            <Row>
              <Col align='center'>
                <Typography component='h3'>Get anywhere access, go mobile</Typography>
              </Col>
            </Row>
            <Row align='center'>
              <ColStyledDownloadLinkContainer align='center'>
                <ul className='reduced'>
                  <ListItemStyledDownLoadLink>
                    <Link href='https://apps.apple.com/us/app/northwestern-mutual/id1132579006' target="_blank" rel="noopener noreferrer nofollow">
                      <img alt='Download on the App Store' src='https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png' className='nmx-icon badge-appStore' />
                      {/* <img alt='Download on the App Store' src={`${props.config.basePath}/template/assets/${props.config.nmxTemplateVersion}/images/apple-app-store-badge.svg`} className='nmx-icon badge-appStore' /> */} {/* TODO: use this badge once MR is merged in */}
                      {/* <AppleAppStoreBadge /> */}
                    </Link>
                  </ListItemStyledDownLoadLink>
                  <ListItemStyledDownLoadLink>
                    <Link href='https://play.google.com/store/apps/details?id=com.nm.nm' target="_blank" rel="noopener noreferrer nofollow">
                      <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className='nmx-icon badge-googlePlay' />
                      {/* <img alt='Get it on Google Play' src={`${props.config.basePath}/template/assets/${props.config.nmxTemplateVersion}/images/google-play-badge.png`} className='nmx-icon badge-googlePlay' /> */} {/* TODO: use this badge once MR is merged in */}
                      {/* <GooglePlayBadge /> */}
                    </Link>
                  </ListItemStyledDownLoadLink>
                </ul>
              </ColStyledDownloadLinkContainer>
            </Row>
          </ColStyledAppDownloadContainer>
        </RowStyled>
      </ContainerModalStyled>
      <DivModalOverlayStyled
        className='nmx-modal__overlay'
        ref={overlay} />
    </DivModalContainerStyled>
  );
};

UserLoginModalComponent.propTypes = {
  /** Config vars used for varying components */
  config: PropTypes.shape({
    basePath: PropTypes.string,
    nmxTemplateVersion: PropTypes.string,
    services: PropTypes.shape({
      loginPageUrl: PropTypes.string.isRequired,
      loginPageUrlBase: PropTypes.string.isRequired,
    }),
  }),
};

UserLoginModalComponent.defaultProps = {
  config: {
    services: {
      loginPageUrl: '<%=loginPageUrl%>',
      loginPageUrlBase: '<%=loginPageUrlBase%>',
    },
  },
};

export default UserLoginModalComponent;

/* eslint-disable no-mixed-operators */
import React, { useEffect } from 'react';
import Location from '@nmx/utils/dist/utilities/frontend/location';
import PropTypes from 'prop-types';
import LazyLoad from 'vanilla-lazyload';
import Head from '../Head';
import Header from '../Header';
import Footer from '../Footer';
import Scripts from '../Scripts';
// import SvgPreload from '../SvgPreload';
import Canvas from '../Canvas';

// Grab UTM codes from the url and store them in the session so they can be utilized later for tracking purposes
import '../../../assets/js/session/utm';

// https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid/2117523#2117523
// eslint-disable-next-line no-bitwise
const getGuid = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));

export const PageComponent = ({
  // CONFIG VARS
  config,
  dynamicConfig,
  // PAGE COMPONENT
  children,
  // HEAD PROPS
  canonical,
  description,
  dynatraceScriptUrl,
  headComponent,
  metaRobotsNoIndexNoFollow,
  siteTitleFirst,
  title,
  useReCaptcha,
  useHeap,
  // HEADER PROPS
  breadcrumbsNavigationConfig,
  customHeader,
  headerIsOverlayed,
  headerLogoEyebrow,
  headerLogoEyebrowLarge,
  headerWithoutBorder,
  hideHeader,
  showInformationalBanner,
  skipNavAside,
  skipNavFooter,
  useAkzidenz,
  // SCRIPTS PROPS
  showChatBot,
  showGooglePlaces,
  // FOOTER PROPS
  appendFooter,
  customDisclaimers,
  customFooter,
  disableDisclaimerTopBorder,
  disclaimerFootnotes,
  isAmp,
  legalCopy,
}) => {
  useEffect(() => {
    // Lazy load images
    // eslint-disable-next-line no-unused-vars
    const lazyload = new LazyLoad({ elements_selector: '.lazy' });
    // Generate a pre client id to usage in adobe and lead records (tie together/back)
    const preClientId = getGuid();
    sessionStorage.setItem('pre-client-id', preClientId);
    window.adobeDataLayer = window.adobeDataLayer || [];

    window.adobeDataLayer.push({
      'user-details': { ids: { 'pre-client-id': preClientId } },
      page: { 'load-path': window.location.pathname.replace(/\/$/, '').toLowerCase() },
    });
    // Generate FB event ID to be sent along with FB conversion pixel events
    const fbEventId = getGuid();
    sessionStorage.setItem('fb-event-id', fbEventId);
    XMLHttpRequest.prototype.open = (function (open) {
      // eslint-disable-next-line no-unused-vars
      return function (method, url, async) {
        // eslint-disable-next-line prefer-rest-params
        open.apply(this, arguments);
        if (url.indexOf('/nmx-api-proxy/nmx/') > 0) {
          this.setRequestHeader('X-Pre-Client-Id', preClientId);
        }
        if (url.indexOf('/nmx-api-proxy/nmx/ms-form-proxy/lead') > 0) {
          this.setRequestHeader('X-Fb-Event-Id', fbEventId);
          this.setRequestHeader('X-Prelead-Contact-Reference-Id', sessionStorage.getItem('prelead-contact-reference-id'));
        }
      };
    }(XMLHttpRequest.prototype.open));
  }, []);

  const preleadContactReferenceId = Location.getQueryParam('preleadContactReferenceId');
  if (preleadContactReferenceId) {
    sessionStorage.setItem('prelead-contact-reference-id', preleadContactReferenceId);
  }

  return (
    <React.Fragment>
      {/* Head properties added to the head tag with Helmet */}
      <Head
        config={config}
        dynamicConfig={dynamicConfig}
        dynatraceScriptUrl={dynatraceScriptUrl}
        Container={headComponent}
        metaRobotsNoIndexNoFollow={metaRobotsNoIndexNoFollow}
        siteTitleFirst={siteTitleFirst}
        title={title}
        description={description}
        canonical={canonical}
        useReCaptcha={useReCaptcha}
        useHeap={useHeap}
        useAkzidenz={useAkzidenz}
      />
      {/* Canvas component, which sets the max canvas size, and allows for gutters on large devices */}
      <Canvas>
        {/* Header and nav for the page */}
        {customHeader || <Header
          breadcrumbsNavigationConfig={breadcrumbsNavigationConfig}
          config={config}
          dynamicConfig={dynamicConfig}
          hideHeader={hideHeader}
          headerIsOverlayed={headerIsOverlayed}
          headerLogoEyebrow={headerLogoEyebrow}
          headerLogoEyebrowLarge={headerLogoEyebrowLarge}
          headerWithoutBorder={headerWithoutBorder}
          showInformationalBanner={showInformationalBanner}
          skipNavAside={skipNavAside}
          skipNavFooter={skipNavFooter}
        />
        }

        {/* Main document where page components are pased down to - everything specific to the page  */}
        <main
          id="main"
          role="main"
          className="nmx-base"
          style={{
            WebkitBoxSizing: 'border-box',
            boxSizing: 'border-box',
          }}>
          {children}
        </main>

        {/* Footer and properties added for the page */}
        <Footer
          appendFooter={appendFooter}
          config={config}
          customDisclaimers={customDisclaimers}
          disclaimerFootnotes={disclaimerFootnotes}
          disableDisclaimerTopBorder={disableDisclaimerTopBorder}
          isAmp={isAmp}
          legalCopy={legalCopy}
          CustomFooter={customFooter}
        />
      </Canvas>
      {/* Page scripts */}
      <Scripts
        config={config}
        showChatBot={showChatBot}
        showGooglePlaces={showGooglePlaces}
      />

      {/* Preloaded svgs */}
      {/* TODO: look and ensure this is even helping */}
      {/* <SvgPreload /> */}
    </React.Fragment>
  );
};

PageComponent.propTypes = {
  /** Env vars used for varying components */
  config: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    nmxTemplateVersion: PropTypes.string.isRequired,
    adobeLaunchScript: PropTypes.string.isRequired,
    heapAppId: PropTypes.string.isRequired,
    public: PropTypes.shape({
      googlePlacesApiKey: PropTypes.string,
      googleAnalyticsTrackingId: PropTypes.string,
      recaptchaV3: PropTypes.string,
    }).isRequired,
    services: PropTypes.shape({
      loginPageUrl: PropTypes.string.isRequired,
      loginPageUrlBase: PropTypes.string.isRequired,
      userLogoutUrls: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dynamicConfig: PropTypes.object,

  /** Data Props */
  fwmData: PropTypes.shape({ useFwmNav: PropTypes.bool.isRequired }),

  /** Component to render inside the page - everything other than header, footer, scripts, etc */
  children: PropTypes.any.isRequired,

  /** HEAD: canonical */
  canonical: PropTypes.string.isRequired,
  /** HEAD: Description to be placed in head description meta tag */
  description: PropTypes.string.isRequired,
  /** HEAD: dynatraceScriptUrl passed in from each respective app, will be different for each instance (INT, QA, and Prod) */
  dynatraceScriptUrl: PropTypes.string,
  /** HEAD: container for head component. Replace with react-helmet or react-scripts helment if not wanting default <head /> */
  headComponent: PropTypes.any,
  /** HEAD: Do not index page with search engines */
  metaRobotsNoIndexNoFollow: PropTypes.bool,
  /** HEAD: Should title meta tag show site title first? */
  siteTitleFirst: PropTypes.bool,
  /** HEAD: Title to be placed in head title meta tag */
  title: PropTypes.string.isRequired,
  /** HEAD: Does this page use recaptcha? If true will place script on page */
  useReCaptcha: PropTypes.bool,
  /** HEAD: Do we want to load heap on the page? default is true, we usually want to load this */
  useHeap: PropTypes.bool,

  /** HEADER: breadcrumb navigation config - will display if !null */
  breadcrumbsNavigationConfig: PropTypes.object,
  /** HEADER: customHeader will override the  */
  customHeader: PropTypes.node,
  /** HEADER: Full primary nav with gradient overlay on top of body content. Coupled w/ hideHeader shows white logo overlayed on top of dark body */
  headerIsOverlayed: PropTypes.bool,
  /** HEADER: Will enable logo-only block header above body content */
  headerLogoEyebrow: PropTypes.bool,
  /** HEADER: Same as headerLogoEyebrow but larger */
  headerLogoEyebrowLarge: PropTypes.bool,
  /** HEADER: Will remove bottom header border logo-only or full nav */
  headerWithoutBorder: PropTypes.bool,
  /** HEADER: Will enable logo-only header and remove any primary navigation including login */
  hideHeader: PropTypes.bool,
  /** HEADER: showInformationalBanner */
  showInformationalBanner: PropTypes.bool,
  /** HEADER: skipNavAside */
  skipNavAside: PropTypes.bool,
  /** HEADER: skipNavFooter */
  skipNavFooter: PropTypes.bool,

  /** FOOTER: TODO - STILL BEING USED? Append additional content to footer. */
  appendFooter: PropTypes.any,
  /** FOOTER: Provide custom disclaimers to left of stock disclaimer */
  customDisclaimers: PropTypes.any,
  /** FOOTER: CustomFooter overrides the footer! */
  customFooter: PropTypes.node,
  /** FOOTER: optional boolean to remove top border (when page last section has a background color other than white) */
  disableDisclaimerTopBorder: PropTypes.bool,
  /** FOOTER: Provide custom disclaimer footnotes to left of stock disclaimer */
  disclaimerFootnotes: PropTypes.any,
  /** FOOTER: Is this an amp page? */
  isAmp: PropTypes.bool,
  /** FOOTER: TODO - STILL BEING USED? Use legal copy. */
  legalCopy: PropTypes.any,
  /** SCRIPTS: Show chatbot */
  showChatBot: PropTypes.bool,
  /** SCRIPTS: Show and add google places script */
  showGooglePlaces: PropTypes.bool,
};

PageComponent.defaultProps = {
  metaRobotsNoIndexNoFollow: false,
  siteTitleFirst: false,

  breadcrumbsNavigationConfig: null,
  headerIsOverlayed: false,
  headerWithoutBorder: false,
  hideHeader: false,
  showInformationalBanner: true,
  skipNavAside: false,
  skipNavFooter: false,

  disableDisclaimerTopBorder: false,
  isAmp: false,

  showChatBot: false,
  showGooglePlaces: false,
  useReCaptcha: false,
  useHeap: true,
};

export default PageComponent;

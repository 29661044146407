import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CONSTANTS, events } from '@gelato/analytics-datalayer';
import FrontendHttpService from '@nmx/utils/dist/services/FrontendHttpService';
// template components
import { Col, Row } from '../../base/Grid';
import Loading from '../../base/Loading';
import Typography from '../../base/Typography';
import WidowBlock from '../../base/WidowBlock';
// Inputs
import EmailInput from './inputs/EmailInput';
import FirstNameInput from './inputs/FirstNameInput';
import LastNameInput from './inputs/LastNameInput';
// image imports
import CheckmarkIcon from './icons/CircleWithCheckmark';
import EmailIcon from './icons/CircleWithEnvelope';
import LowerLeftDotPattern from './icons/LowerLeftCornerDotPattern';
import UpperRightDotPattern from './icons/UpperRightCornerDotPattern';

// styles
import {
  ButtonStyled,
  ColContainerStyled,
  ColInputContainerStyled,
  DivFormContainerStyled,
  DivIconContainerStyled,
  DivLeftPatternContainerStyled,
  DivLoadingContainerStyled,
  DivRightPatternContainerStyled,
  FieldsetStyled,
  FormStyled,
  LegendStyled,
  RowConfirmationStyled,
  SectionContainerStyled,
  TypographyHeaderStyled,
  TypographyStyled,
} from './styles';
// utilities
import {
  buildPreleadPayload,
  validateFirstName,
  validateLastName,
  validateEmail,
} from './utilities/utilities';

const { PRE_LEAD_SUSPECT_EMAIL_CAPTURE_FORM } = CONSTANTS.NAME_LEAD_FORM_EXPERIENCES;

const { PRE_LEAD_EMAIL } = CONSTANTS.NAME_LEAD_FORM_SUBMIT_TYPE;

const preLeadHttpService = new FrontendHttpService({
  baseURL: '<%=preLeadApiBaseUrl%>',
  fullResponse: true,
});

export const SuspectEmailCaptureFormComponent = ({
  className,
  config,
  formLocation,
  id,
  journey,
  onFormEngaged,
}) => {
  // React state Hooks ////////////////////////////////
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  // Error messages are set by this component's native utilities.js validators.
  const [errorMessages, setErrorMessages] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [failedPreviousAttempt, setFailedPreviousAttempt] = useState(false);
  const [formHasErrors, setFormHasErrors] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShowingThankYou, setIsShowingThankYou] = useState(false);
  const [isShowingErrorScreen, setIsShowingErrorScreen] = useState(false);

  // EVENT HANDLERS ///////////////////////////////////////
  const handleChange = (e) => {
    // update values for user inputs
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    // Inform parent component of engagement with form
    onFormEngaged();
  };

  const handleInputClick = () => {
    onFormEngaged();
  };

  // Fires on input blur and autofills
  const analyticsPush = (e) => {
    const { name } = e.target;
    if (window && typeof window !== 'undefined') {
      events.leadForms.interact({
        experience: PRE_LEAD_SUSPECT_EMAIL_CAPTURE_FORM,
        actionType: name,
        actionValues: 'complete',
      });
    }
  };

  const validateForm = () => {
    const firstNameResponse = validateFirstName(values.firstName);
    const lastNameResponse = validateLastName(values.lastName);
    const emailResponse = validateEmail(values.email);

    setFormHasErrors(
      firstNameResponse[0]
      || lastNameResponse[0]
      || emailResponse[0],
    );

    setErrors({
      firstName: firstNameResponse[0],
      lastName: lastNameResponse[0],
      email: emailResponse[0],
    });

    // If there are errors, set the appropriate error message returned from the validators.
    setErrorMessages({
      firstName: firstNameResponse[1],
      lastName: lastNameResponse[1],
      email: emailResponse[1],
    });
  };

  // begin the submission process
  const handleReCaptchaCallback = (recaptchaReponseToken) => {
    let payload = null;

    payload = buildPreleadPayload(
      values,
      recaptchaReponseToken,
      journey,
    );

    payload.is_recaptcha_v3 = true;

    // ░██████╗██╗░░░██╗██████╗░███╗░░░███╗██╗████████╗ // ███████╗░█████╗░██████╗░███╗░░░███╗
    // ██╔════╝██║░░░██║██╔══██╗████╗░████║██║╚══██╔══╝ // ██╔════╝██╔══██╗██╔══██╗████╗░████║
    // ╚█████╗░██║░░░██║██████╦╝██╔████╔██║██║░░░██║░░░ // █████╗░░██║░░██║██████╔╝██╔████╔██║
    // ░╚═══██╗██║░░░██║██╔══██╗██║╚██╔╝██║██║░░░██║░░░ // ██╔══╝░░██║░░██║██╔══██╗██║╚██╔╝██║
    // ██████╔╝╚██████╔╝██████╦╝██║░╚═╝░██║██║░░░██║░░░ // ██║░░░░░╚█████╔╝██║░░██║██║░╚═╝░██║
    // ╚═════╝░░╚═════╝░╚═════╝░╚═╝░░░░░╚═╝╚═╝░░░╚═╝░░░ // ╚═╝░░░░░░╚════╝░╚═╝░░╚═╝╚═╝░░░░░╚═╝

    try {
      preLeadHttpService.post('/prelead', payload)
        .then(() => {
          // Success!
          setIsShowingThankYou(true);
          setIsSubmitting(false);
          if (window && typeof window !== 'undefined') {
            events.leadForms.complete({
              experience: PRE_LEAD_SUSPECT_EMAIL_CAPTURE_FORM,
              submissionType: PRE_LEAD_EMAIL,
            });
          }
        })
        .catch((error) => {
          setIsShowingErrorScreen(true);
          setIsSubmitting(false);
          console.error(`Error submitting prelead on ${formLocation}.`, error);
        });
    } catch (error) {
      setIsShowingErrorScreen(true);
      setIsSubmitting(false);
      console.error(error);
      events.leadForms.completeFailure({
        experience: PRE_LEAD_SUSPECT_EMAIL_CAPTURE_FORM,
        errorMsg: `Unable to submit prelead email capture form on ${formLocation} with error ${error}`,
      });
    }
  };

  // call this function when we've validated the form and are ready to submit
  const executeRecaptchaAndSubmit = () => {
    if (window.grecaptcha) {
      try {
        // recaptchaV3 method for executing
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(config.public.recaptchaV3, { action: 'submit' }).then((token) => {
            document
              .querySelectorAll('.recaptchaResponse')
              // eslint-disable-next-line no-param-reassign
              .forEach((elem) => { elem.value = token; });
            handleReCaptchaCallback(token);
          });
        });
      } catch (error) {
        // if recaptcha execute fails
        setIsShowingErrorScreen(true);
        setIsSubmitting(false);
        console.error(`Error executing recaptcha at form submit at path: ${window.location.pathname}.`, error);
      }
    }
  };

  // call this function when we've validated the form and there's an error
  const resetRecaptcha = () => {
    if (window.grecaptcha) {
      try {
        // recaptchaV3 method for executing
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(config.public.recaptchaV3, { action: 'reset' })
            .then((token) => {
              document
                .querySelectorAll('.recaptchaResponse')
                // eslint-disable-next-line no-param-reassign
                .forEach((elem) => { elem.value = token; });
            });
        });
      } catch (error) {
        // if recaptcha execute fails, show error
        setIsShowingErrorScreen(true);
        setIsSubmitting(false);
        console.error(error);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // final check for errors
    validateForm();
    setIsSubmitting(true);
  };

  // useEffect HOOKS ////////////////////////////////////
  useEffect(() => {
    events.leadForms.load({ experience: PRE_LEAD_SUSPECT_EMAIL_CAPTURE_FORM });
  }, []);

  // final check to make sure the form is error free and the user is submitting
  useEffect(() => {
    if (isSubmitting) {
      if (!formHasErrors) {
        executeRecaptchaAndSubmit();
      } else {
        setIsSubmitting(false);
        resetRecaptcha();
      }
    }
  }, [isSubmitting]);

  useEffect(() => {
    setFailedPreviousAttempt(formHasErrors);
  }, [formHasErrors]);

  useEffect(() => {
    // validate form in real-time if the user has had a failed attempt that gave user input errors.
    // A valid form will reactivate the submit button.
    if (failedPreviousAttempt) {
      validateForm();
    }
  }, [values]);

  return (
    <SectionContainerStyled
      className={className}
      id={id}
      moduleName='suspect-email-capture-form-module'
      moduleVariation='A'
      variant='tertiary'>
      <DivLeftPatternContainerStyled className='left-pattern-container'>
        <LowerLeftDotPattern />
      </DivLeftPatternContainerStyled>
      <DivRightPatternContainerStyled className='right-pattern-container'>
        <UpperRightDotPattern />
      </DivRightPatternContainerStyled>
      {/* SETS THE WIDTH OF THE WHITE BOX CONTAINER */}
      <Row align='center'>
        <Col
          xsmall={12}
          medium={11}
          large={10}
          xxlarge={8}>
          {/* WHITE BOX */}
          <DivFormContainerStyled>
            <Row align='center'>
              <ColContainerStyled xsmall={11}>
                <DivIconContainerStyled>
                  {isShowingThankYou && (
                    <CheckmarkIcon />
                  )}
                  {(!isShowingThankYou && !isShowingErrorScreen) && (
                    <EmailIcon />
                  )}
                </DivIconContainerStyled>
                {/* SETS THE WIDTH OF THE FORM INPUTS AND CONTENT */}
                <Row align='center'>
                  {(!isShowingThankYou && !isShowingErrorScreen && !isSubmitting) && (
                    <Col
                      xsmall={12}
                      align='center'>
                      <TypographyHeaderStyled
                        id='suspect-email-capture-form-header'
                        component='h2'
                        variant='h3'>
                        Get our weekly email series filled with financial tips, tools, <WidowBlock>and more.</WidowBlock>
                      </TypographyHeaderStyled>
                    </Col>
                  )}
                  {/* FORM CONTAINER */}
                  <Col xsmall={12}>
                    {(!isShowingThankYou && !isShowingErrorScreen && !isSubmitting) && (
                      <FormStyled
                        id={`${id}-form`}
                        onSubmit={handleSubmit}>
                        <FieldsetStyled>
                          <LegendStyled>
                            Enter your contact information to receive weekly emails about reaching your goals.
                          </LegendStyled>
                          <Row align='center'>
                            {/* FIRST NAME INPUT //////////////////////////////////////////////////////////////////// */}
                            <ColInputContainerStyled
                              xsmall={12}
                              small={6}
                              large={3}>
                              <FirstNameInput
                                value={values.firstName}
                                onBlur={analyticsPush}
                                onChange={handleChange}
                                onClick={handleInputClick}
                                error={errors.firstName}
                                errorMessage={errorMessages.firstName}
                              />
                            </ColInputContainerStyled>
                            {/* LAST NAME INPUT //////////////////////////////////////////////////////////////////// */}
                            <ColInputContainerStyled
                              xsmall={12}
                              small={6}
                              large={3}>
                              <LastNameInput
                                value={values.lastName}
                                onBlur={analyticsPush}
                                onChange={handleChange}
                                onClick={handleInputClick}
                                error={errors.lastName}
                                errorMessage={errorMessages.lastName}
                              />
                            </ColInputContainerStyled>
                            {/* EMAIL INPUT //////////////////////////////////////////////////////////////////// */}
                            <ColInputContainerStyled
                              xsmall={12}
                              large={6}
                              xlarge={4}>
                              <EmailInput
                                value={values.email}
                                onBlur={analyticsPush}
                                onChange={handleChange}
                                onClick={handleInputClick}
                                error={errors.email}
                                errorMessage={errorMessages.email}
                              />
                            </ColInputContainerStyled>
                            <ColInputContainerStyled
                              xsmall={12}
                              xlarge={2}
                              disableBottomPadding>
                              <ButtonStyled
                                id='suspect-email-capture-form-submit-button'
                                variant='secondary'
                                type='submit'
                                disabled={formHasErrors}
                                formvariant='primary'
                                ariaLabel='Submit form to sign up for weekly emails.'>
                                Sign me up
                              </ButtonStyled>
                            </ColInputContainerStyled>
                          </Row>
                        </FieldsetStyled>
                      </FormStyled>
                    )}

                    {isSubmitting && (
                      <RowConfirmationStyled align='center'>
                        <Col
                          xsmall={12}
                          align='center'>
                          <DivLoadingContainerStyled>
                            <Loading/>
                          </DivLoadingContainerStyled>
                        </Col>
                      </RowConfirmationStyled>
                    )}

                    {isShowingThankYou && (
                      <RowConfirmationStyled align='center'>
                        <Col
                          xsmall={12}
                          align='center'>
                          <Typography variant='h3'>
                            Got it&#33; Thanks, <span id='prelead-suspect-email-capture-form-confirmation-first-name'>{values.firstName}</span>.
                          </Typography>
                          <TypographyStyled disableBottomPadding>
                            We&#39;re excited to share our Financial Knowledge Series with you. Your first email will arrive shortly.
                          </TypographyStyled>
                        </Col>
                      </RowConfirmationStyled>
                    )}

                    {isShowingErrorScreen && (
                      <RowConfirmationStyled align='center'>
                        <Col
                          xsmall={12}
                          align='center'>
                          <Typography variant='h2'>
                            Oops&#33; Something went wrong.
                          </Typography>
                          <Typography
                            variant='h2'
                            disableBottomPadding>
                            Please try again later.
                          </Typography>
                        </Col>
                      </RowConfirmationStyled>
                    )}
                  </Col>
                </Row>
              </ColContainerStyled>
            </Row>
          </DivFormContainerStyled>
        </Col>
      </Row>
      <input
        type='hidden'
        name='recaptcha_response'
        className='recaptchaResponse' />
    </SectionContainerStyled>
  );
};

SuspectEmailCaptureFormComponent.propTypes = {
  /** optional additional className */
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  formLocation: PropTypes.string.isRequired,
  config: PropTypes.shape({ public: PropTypes.shape({ recaptchaInvisible: PropTypes.string }).isRequired }),
  /** This callback is used to tell parent component that the form has first been engaged with */
  onFormEngaged: PropTypes.func,
  journey: PropTypes.string.isRequired,
};

SuspectEmailCaptureFormComponent.defaultProps = {
  // Neccesarry to allow legacy apps to capture the configs with string replace
  config: { public: { recaptchaInvisible: '<%=recaptchaInvisible%>' } },
  onFormEngaged: () => {},
};

export default SuspectEmailCaptureFormComponent;

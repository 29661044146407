import { isString, isPhoneNumber, isEmail, isZipCode, isEmpty } from '@nmx/utils/dist/utilities/validators';
import Session from '@nmx/utils/dist/utilities/frontend/session';

// NOTE for input validators. Successful validation returns an object of [true, ''].
// The boolean to handle the error state, and the string to handle the error message.
// A failed validation of an input will return [false, 'The appropriate error message']
// STEP 1 ////////////////////////////////////////////////////////////////////////////////
export const validateGoal = (goal) => {
  let res = [true, ''];
  if (isEmpty(goal)) {
    res = [false, 'Select an option.'];
  }
  return res;
};

// True or empty string is good. Populated string response or false is bad..
//  STEP 2 ////////////////////////////////////////////////////////////////////////////////
export const validateAge = (age) => {
  const ageString = (age || '').toString();
  const ageStringCheck = !!ageString && !isEmpty(ageString) && /^[0-9]+$/.test(ageString);
  const ageInt = parseInt(ageString, 10);
  let res = [true, ''];

  if (!ageString || ageString.length < 1) {
    res = [false, 'Enter age'];
  } else if (!ageStringCheck) {
    res = [false, 'Digits only'];
  } else if ((ageInt < 18 || ageInt > 125) || ageString.charAt(0) === '0') {
    res = [false, 'Must be between 18-120.'];
  }
  return res;
};

export const validateZipCode = (zip) => {
  let res = [true, ''];
  if (isEmpty(zip) || !isString(zip)) {
    res = [false, 'Enter ZIP code.'];
  } else if (!isZipCode(zip)) {
    res = [false, 'Must be 5 digits.'];
  }
  return res;
};

export const validateIncomeRange = (incomeRange) => {
  let res = [true, ''];
  if (!incomeRange || isEmpty(incomeRange)) {
    res = [false, 'Select an option.'];
  }
  return res;
};

// only required for leads when income range is below $40K
export const validateWealthRange = (wealthRange, incomeRange) => {
  let res = [true, ''];
  if (!wealthRange && (incomeRange <= 39999)) {
    res = [false, 'Select an option.'];
  }
  return res;
};

//  STEP 3 ////////////////////////////////////////////////////////////////////////////////
export const validateFirstName = (name) => {
  let res = [true, ''];
  if (isEmpty(name) || !isString(name) || name.length > 150) {
    res = [false, 'Enter first name.'];
  }
  return res;
};

export const validateLastName = (name) => {
  let res = [true, ''];
  if (isEmpty(name) || !isString(name) || name.length > 150) {
    res = [false, 'Enter last name.'];
  }
  return res;
};

//  STEP 4 ////////////////////////////////////////////////////////////////////////////////
export const validatePhone = (phone) => {
  let res = [true, ''];
  if (isEmpty(phone)) {
    res = [false, 'Enter phone number.'];
  } else if (!isPhoneNumber(phone)) {
    res = [false, 'Please enter a valid phone number.'];
  }
  return res;
};

export const validateEmail = (email) => {
  let res = [true, ''];
  if (isEmpty(email)) {
    res = [false, 'Enter email.'];
  } else if (!isEmail(email)) {
    res = [false, 'Enter valid email address.'];
  }
  return res;
};
// ////////////////////////////////////////////////////////////////////////////////
// REAL-TIME INPUT VALIDATORS
// Note: Validators return an object, a bool for the error state, and an appropriate error message.
export const validateCurrentInput = (e, incomeRange) => {
  const { name, value } = e.target;
  let res;
  switch (name) {
    case 'goal':
      res = validateGoal(value);
      break;
    case 'age':
      res = validateAge(value);
      break;
    case 'zip':
      res = validateZipCode(value);
      break;
    case 'incomeRange':
      res = validateIncomeRange(value);
      break;
    case 'wealthRange':
      res = validateWealthRange(value, incomeRange);
      break;
    case 'firstName':
      res = validateFirstName(value);
      break;
    case 'lastName':
      res = validateLastName(value);
      break;
    case 'email':
      res = validateEmail(value);
      break;
    case 'phone':
      res = validatePhone(value);
      break;
    default:
    // For non-required fields, always return the success object
      res = [true, ''];
  }
  return res;
};

// STEP VALIDATORS  /////////////////////////////////////////////
// STEP VALIDATORS NEED TO RETURN TRUE FOR THE VIEW TO BE VALID

// NOTE: These validators return an object, containing a bool and string. Appending [0] after the function will return just the bool from the object.
export const validateStep1 = (goal) => validateGoal(goal)[0];

export const validateStep2 = (age, zip, incomeRange, wealthRange) => (validateAge(age)[0]
  && validateZipCode(zip)[0]
  && validateIncomeRange(incomeRange)[0]
  && validateWealthRange(wealthRange, incomeRange)[0]);

export const validateStep3 = (firstName, lastName) => (validateFirstName(firstName)[0]
  && validateLastName(lastName)[0]);

export const validateStep4 = (email, phone) => (validateEmail(email)[0]
  && validatePhone(phone)[0]);

export const buildLeadPayload = (
  values,
  agentNumber,
  campaignName,
  recaptchaReponseToken,
) => ({
  goal: values.goal,
  age: values.age,
  zip: values.zip,
  income_range: values.incomeRange,
  wealth_range: values.wealthRange,
  first_name: values.firstName,
  last_name: values.lastName,
  email: values.email,
  phone: values.phone,
  campaign_name: campaignName,
  agent_number: agentNumber,
  page_url: (typeof window !== 'undefined')
    ? window.location.href
    : '',
  recaptcha_response_token: recaptchaReponseToken,
  utm_campaign: Session.get('utmCampaign'),
  utm_content: Session.get('utmContent'),
  utm_medium: Session.get('utmMedium'),
  utm_source: Session.get('utmSource'),
  utm_term: Session.get('utmTerm'),
});

// STATE OBJECTS
export const initialActiveViewsObj = {
  isActiveViewStep1: false,
  isActiveViewStep2: false,
  isActiveViewStep3: false,
  isActiveViewStep4: false,
  isActiveViewIsSubmitting: false,
  isActiveViewThankYou: false,
  isActiveViewError: false,
};
export const initialAccessibilityObj = {
  isAccessibleStep1: false,
  isAccessibleStep2: false,
  isAccessibleStep3: false,
  isAccessibleStep4: false,
  isAccessibleIsSubmitting: false,
  isAccessibleThankYou: false,
  isAccessibleError: false,
};

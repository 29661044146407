import { isString, isEmail, isEmpty } from '@nmx/utils/dist/utilities/validators';
import Session from '@nmx/utils/dist/utilities/frontend/session';

// TRUE IS BAD
export const validateFirstName = (name) => {
  let res = [false, ''];
  if (isEmpty(name) || !isString(name)) {
    res = [true, 'Enter first name'];
  }
  if (name.length > 150) {
    res = [true, 'Exceeded character limit'];
  }

  return res;
};

export const validateLastName = (name) => {
  let res = [false, ''];
  if (isEmpty(name) || !isString(name)) {
    res = [true, 'Enter last name'];
  }
  if (name.length > 150) {
    res = [true, 'Exceeded character limit'];
  }
  return res;
};

export const validateEmail = (email) => {
  let res = [false, ''];
  if (isEmpty(email)) {
    res = [true, 'Enter email'];
  } else if (!isEmail(email)) {
    res = [true, 'Enter valid email address'];
  }
  return res;
};

export const buildPreleadPayload = (
  values,
  recaptchaReponseToken,
  journey,
) => ({
  firstName: values.firstName,
  lastName: values.lastName,
  email: values.email,
  source: 'NM.com',
  topic: 'Suspect Email Capture', // Update this??? Is this a good value?
  channel: 'website',
  recaptcha_response_token: recaptchaReponseToken,
  journey,
  utm_campaign: Session.get('utmCampaign'),
  utm_content: Session.get('utmContent'),
  utm_medium: Session.get('utmMedium'),
  utm_source: Session.get('utmSource'),
  utm_term: Session.get('utmTerm'),
});

